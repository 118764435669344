import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@modules/shared/services/authentication.service';
import { Observable, first } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url) {
      this.authenticationService.currentRouteState = state.url;
    }
    return this.authenticationService.currentUserSubject.pipe(
      first(),
      map((res) => !!res),
    );
  }
}
