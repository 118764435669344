import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { LoginModalComponent } from '@modules/login/login-modal/login-modal.component';
import { AuthenticationService } from '@modules/shared/services/authentication.service';
import { Notification } from '@modules/shared/services/notification';
import { Util } from '@modules/shared/services/util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authenticationService: AuthenticationService,
    private notification: Notification,
    private util: Util,
    private modalService: BsModalService,
    private ngxLoader: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        this.ngxLoader.stop();
        if (err?.status) {
          if (err?.status === 404) {
            return throwError(() => err);
          } else if (err?.status === 401) {
            if (err.error?.error_description?.includes('Invalid user credentials')) {
              return throwError(() => err);
            }
            return this.handle401Error(request, next);
          } else if (err?.status === 400) {
            if (err.url.includes(environment.authUrl)) {
              if (err.error.error === 'invalid_grant') {
                this.authenticationService.logout().subscribe();
                location.reload();
              } else if (err.error.error_description.includes('Refresh token expired')) {
                this.authenticationService.logout().subscribe();
                location.reload();
              } else {
                if (!['login'].includes(this.activatedRoute.snapshot['_routerState'].url.split(';')[0].replace('/', ''))) {
                  this.showAuthModal();
                }
              }
            } else {
              if (err.url.includes('clientChanged')) {
                return throwError(() => err);
              }
              if (err?.error?.message?.ru) {
                this.notification.showError(err.error.message[this.util.getError()], 'Ошибка');
              } else {
                this.notification.showError('Произошла ошибка', 'Ошибка');
              }
            }
          }
          if (err?.status !== 400) {
            if (!this.util.isNullOrEmpty(err?.error?.message?.ru)) {
              this.notification.showInfo('Информация', err.error.message[this.util.getError()]);
            } else if (err?.status >= 500) {
              this.notification.showError('Ошибка', 'Ошибка на сервере');
            }
          }
          return throwError(err);
        } else {
          const error = err.error?.message || err.statusText;
          return throwError(error);
        }
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refreshExpiredToken().pipe(
        switchMap((token) => {
          this.isRefreshing = false;

          this.authenticationService.token = token;

          this.authenticationService.update();
          this.refreshTokenSubject.next(token.access_token);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.authenticationService.accessToken}`,
            },
          });
          return next.handle(request);
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((jwt) => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt}`,
            },
          });
          return next.handle(request);
        }),
      );
    }
  }

  showAuthModal() {
    this.modalService.show(LoginModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
      // initialState: {
      //   centered: true
      // }
    });
  }
}
