import * as um from 'src/generated/api/um.api';

type CreateUser = um.paths['/api/profile-client']['post'];
export type CreateUserParams = CreateUser['parameters']['body']['profile'];
export type CreateUserResp = CreateUser['responses']['200']['schema'];

type UpdateClient = um.paths['/api/profile-client/crm']['put'];
export type UpdateClientParams = UpdateClient['parameters'];
export type UpdateClientResp = UpdateClient['responses']['200']['schema'];

type GetMyLastLogin = um.paths['/api/users/getMyLastLogin'];
export type GetMyLastLoginResponse = GetMyLastLogin['get']['responses']['200']['schema'];
