import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-floating-point-input-value-view',
  templateUrl: './single-floating-point-value-view.component.html',
  styleUrls: ['./single-floating-point-value-view.component.scss'],
})
export class SingleFloatingPointValueViewComponent {
  @Input()
  public area = 0;

  public get value(): string {
    return typeof this.area === 'number' ? this.area.toFixed(2).toString().replace('.', ',') : this.area;
  }
}
