import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import * as HLS from 'hls.js';

import { STREAMING_SERVICE_URI } from './video-player.module';

@Component({
  selector: 'app-video-player',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<video [poster]="poster" id="video-{{ prefix }}-{{ id }}" controls [autoplay]="playVideo" [muted]="true" loop></video>',
  styles: [
    `
      :host {
        width: inherit;
        height: inherit;
        border-radius: inherit;
      }

      video {
        width: inherit;
        height: inherit;
        object-fit: contain;
        border-radius: inherit;
      }
    `,
  ],
})
export class VideoPlayerComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public readonly id: number;
  @Input() public playVideo: boolean;
  @Input() public readonly source: string;
  @Input() public readonly prefix: string;

  public horizontal: boolean = null;
  poster = 'assets/images/video-loading.png';
  #player: HLS.default;
  private videoEl: HTMLMediaElement;
  readonly #videoServiceURI: string;

  constructor(
    @Inject(STREAMING_SERVICE_URI) private streamingServiceURI: string,
    private cdr: ChangeDetectorRef,
  ) {
    this.#videoServiceURI = this.streamingServiceURI;
  }

  ngAfterViewInit() {
    this.videoEl = this.#getVideoElement();
    if (this.videoEl) this.#initPlayer(this.videoEl);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.playVideo?.currentValue !== changes?.playVideo?.previousValue) {
      if (changes.playVideo.currentValue) {
        this.playVideo = changes.playVideo.currentValue;
      } else {
        this.playVideo = changes.playVideo.currentValue;
      }
      if (this.videoEl) {
        this.#togglePlayer();
      }
    }
  }

  ngOnDestroy() {
    if (this.#player) this.#player.destroy();
  }

  #togglePlayer() {
    if (this.videoEl) {
      this.playVideo ? this.#play() : this.#pause();
    }
  }

  #initPlayer(videoEl: HTMLMediaElement) {
    if (!this.source) {
      console.warn('Source not provided for', this.prefix, this.id);
      return;
    }

    const manifestUri = this.#videoServiceURI + this.source;

    this.#player = new HLS.default({
      capLevelToPlayerSize: true,
      maxBufferSize: 1024,
      backBufferLength: 30,
    });

    this.#player.attachMedia(videoEl);
    this.#player.loadSource(manifestUri);
    this.#player.startLoad();

    this.#player.on(HLS.Events.MANIFEST_PARSED, (event, data) => {
      this.#handleAspectRatio(data.levels?.[0]?.width, data.levels?.[0]?.height);
      this.cdr.detectChanges();
    });

    this.#player.on(HLS.Events.ERROR, (event, data) => {
      console.log(event, data);
    });
  }

  #play() {
    if (this.videoEl) {
      const play = this.videoEl.play();
      if (play !== undefined) {
        play.then().catch((e) => {
          console.log(e);
        });
      }
    }
  }

  isVideoPlaying = (video: HTMLMediaElement) => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

  #pause() {
    if (this.isVideoPlaying(this.videoEl)) {
      this.videoEl.pause();
    }
  }

  #getVideoElement(): HTMLMediaElement {
    const element = document.getElementById(`video-${this.prefix}-${this.id}`) as HTMLMediaElement;
    if (element) return element;
    else {
      console.warn('Video element no found for', this.prefix, this.id);
      return null;
    }
  }

  #handleAspectRatio = (width: number, height: number) => (this.horizontal = width > height);
}
