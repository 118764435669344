import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { EventsDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private http: HttpClient) {}

  addEvent(dto: EventsDto) {
    return this.http.post<any>(`${environment.apiDataManagerUrl}/api/events`, dto);
  }

  getEventById(id: number) {
    return this.http.post<any>(`${environment.apiDataManagerUrl}/api/events/${id}`, {});
  }

  updateEvent(id: number, dto: EventsDto) {
    return this.http.put<any>(`${environment.apiDataManagerUrl}/api/events/${id}`, dto);
  }

  deleteEventById(id: number) {
    return this.http.delete<any>(`${environment.apiDataManagerUrl}/api/events/${id}`);
  }

  getEventsForCalendar(searchFilter: any) {
    return this.http.post<any>(`${environment.apiViewManagerUrl}/api/events/eventsForCalendar`, searchFilter);
  }

  getEventsByDate(date: any) {
    return this.http.post<any>(`${environment.apiViewManagerUrl}/api/events/eventsToDate`, { date });
  }

  getEventsByApplicationId(searchParams: any) {
    return this.http.post<any>(`${environment.apiViewManagerUrl}/api/events/applicationEvents`, searchParams);
  }

  putCommentEvent(id: number, comment: string) {
    return this.http.put<any>(`${environment.apiDataManagerUrl}/api/events/${id}/comment`, comment);
  }

  putCommentEventAsync(id: number, comment: string) {
    return this.http.put<any>(`${environment.apiDataManagerUrl}/api/events/${id}/comment`, comment).toPromise();
  }
}
