import { BaseEntity } from './base.entity';

/**
 * Модель краткой формы заявки
 */
export class ApplicationLightDto implements BaseEntity {
  public id?: number; // ID заявки
  public clientId?: number; // ИД клиента
  public clientLogin?: string; // Модель сущности Клиент
  public operationTypeId?: number = null; // тип операции
  public note?: string; // Примечание
  public agent?: string; // Логин агента, на кого назначена заявка
  public objectTypeId?: number; // Тип объекта
}
