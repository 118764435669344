import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationsUtil } from '../../../notifications.util';
import { GetUserInfoResp } from '../models';
import { LoginService } from './login.service';
import { Util } from './util';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject: Subject<GetUserInfoResp> = new ReplaySubject<GetUserInfoResp>(1);
  public currentUser: Observable<GetUserInfoResp> = this.currentUserSubject.asObservable();
  public currentRouteState = '';
  private readonly JWT_TOKEN = 'token';
  private readonly CLIENT_ID = 'htc';

  private options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
  };

  constructor(
    private readonly http: HttpClient,
    private readonly util: Util,
    private readonly activatedRoute: ActivatedRoute,
    private readonly notificationsUtil: NotificationsUtil,
    private _loginService: LoginService,
  ) {}

  public get currentUserValue(): GetUserInfoResp {
    return this.user;
  }

  public setCurrentUser(user): void {
    this.currentUserSubject.next(user);
  }

  public update(): void {
    this.currentUserSubject = new ReplaySubject<GetUserInfoResp>(1);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public login(credentials: { username: string; password }): Observable<RawToken> {
    return this.loginIDP(credentials).pipe(tap((token: RawToken) => (this.token = token)));
  }

  public get user(): GetUserInfoResp {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  public set user(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  public loginIDP(loginForm: any): Observable<RawToken> {
    const body = new HttpParams().set('username', loginForm.username).set('password', loginForm.password).set('grant_type', 'password').set('client_id', this.CLIENT_ID);
    return this.http.post<any>(`${environment.authUrl}`, body.toString(), this.options);
  }

  public refreshExpiredToken(): Observable<RawToken> {
    const body = new HttpParams().set('refresh_token', this.refreshToken).set('grant_type', 'refresh_token').set('client_id', this.CLIENT_ID);
    return this.http.post<any>(`${environment.authUrl}`, body.toString(), this.options).pipe(
      tap((token) => {
        this.token = token;
      }),
    );
  }

  public logout(): Observable<any> {
    return of({}).pipe(
      tap(() => {
        localStorage.clear();
        sessionStorage.clear();
        this.currentUserSubject.next(null);
        this._loginService.emitLoading(false);
        if (!['login'].includes(this.activatedRoute.snapshot['_routerState'].url.split(';')[0].replace('/', ''))) {
          this.util.dnHref('login');
          this.currentRouteState = null;
          this.notificationsUtil.webSocketDisconnect();
        }
      }),
    );
  }

  public updatePassword(id: number, pass: string) {
    return this.http.put<any>(`${environment.apiUserManagerUrl}/api/users/${id}/pass`, pass);
  }

  createRecoveryCodeByEmail(email: string) {
    return this.http.get(`${environment.apiUserManagerUrl}/open-api/users/createRecoveryCodeNotification/${email}`);
  }

  public changePassByCode(code: string, email: string, password: string): Observable<any> {
    return this.http.get(`${environment.apiUserManagerUrl}/open-api/users/checkRecoveryCode?code=${code}&email=${email}&password=${password}`);
  }

  get token(): RawToken {
    return JSON.parse(localStorage.getItem(this.JWT_TOKEN));
  }

  set token(token) {
    localStorage.setItem(this.JWT_TOKEN, JSON.stringify(token));
  }

  get accessToken(): string {
    return this.token?.access_token ?? null;
  }

  get refreshToken(): string {
    return this.token?.refresh_token ?? null;
  }

  get isAuthenticated(): boolean {
    console.log(this.currentUserValue);
    const authenticated = !!this.currentUserValue;
    if (authenticated === false) {
      this.logout();
    }
    return authenticated;
  }

  get tokenExpired() {
    return this.token?.refresh_expires_in > Date.now();
  }
}

type RawToken = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  'not-before-policy': number;
  session_state: string;
  scope: string;
};
