<div class="card">
  <div class="modal-header">
    <div class="title-block">
      <p class="title mb-0">{{ title }}</p>
      <p class="sub-title" *ngIf="subTitle">{{ subTitle }}</p>
    </div>
    <button type="button" class="close" (click)="close(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="btns">
    <button type="button" class="btn btn-success" (click)="close(true)">
      Да
    </button>
    <button type="button" class="btn btn-dark" (click)="close(false)">
      Отмена
    </button>
  </div>
</div>
