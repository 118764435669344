import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoManagerService {
  constructor(
    private http: HttpClient,
    handler: HttpBackend,
  ) {
    this.http = new HttpClient(handler);
  }

  public uploadVideoDirectMinio(url: string, file): Observable<any> {
    return this.http.put(`${url}`, file);
  }
}
