import * as dm from 'src/generated/api/dm.api';

import { BaseEntity } from './base.entity';

export type MultiLang = {
  nameKz?: string;
  nameEn?: string;
  nameRu?: string;
};

export class Dic implements BaseEntity {
  public id?: number;
  public nameKz?: string;
  public nameEn?: string;
  public nameRu?: string;
  public code?: string;
  public dictionaryName?: string;
  public parentId?: number;
  public operationCode?: string;
  public value?: string | number;
  public label?: string;
  public boardData?: any;
  public longitude?: number;
  public latitude?: number;
  public name?: string;
  public isLeadReason?: boolean;
  public number?: string;
  multiLang?: MultiLang;
}

// TODO: Добавить документацию на API @https://jira.jurta.kz/browse/JURTA-3606
type GetDictionariesWithValues = dm.paths['/api/dictionaries/getByDictionaries']['get'];
export type GetDictionariesWithValuesParams = GetDictionariesWithValues['parameters']['query']['dictionaryName'];
export type GetDictionariesWithValuesResp = GetDictionariesWithValues['responses']['200']['schema'];

export enum DictionaryList {
  Role = 'role',
  Organization = 'organization',
  Achievement = 'achievement',
  Group = 'group',
  OperationType = 'OperationType',
  ObjectType = 'ObjectType',
  LeadStatus = 'LeadStatus',
}

// TODO: Добавить документацию на API @https://jira.jurta.kz/browse/JURTA-3613
type GetDictionary = dm.paths['/api/dictionaries/{dictionaryName}/list']['get'];
export type GetDictionaryParams = GetDictionary['parameters']['path']['dictionaryName'];
export type GetDictionaryResp = GetDictionary['responses']['200']['schema'];
