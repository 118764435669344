import { Pipe, PipeTransform } from '@angular/core';
import { getCodeByNumber } from '@helpers/phone-number';
import { MASK_PHONE, PREFIX_PHONE } from 'src/const';

@Pipe({
  name: 'showMaskPhone',
})
export class ShowMaskPhonePipe implements PipeTransform {
  maskPhone = MASK_PHONE;
  prefixPhone = PREFIX_PHONE;
  buffer = [];
  transform(value: any, isMask = true) {
    let mask = this.maskPhone[getCodeByNumber(value)].replaceAll(0, '_');
    if (isMask) {
      for (let i = 0; i < value.length; i++) {
        mask = mask.replace('_', value[i]);
      }
    } else {
      mask = value;
    }
    return this.prefixPhone[getCodeByNumber(value)] + mask;
  }
}
