export class Part {
  partNumber: number;
  etag: string;
  lastModified: string;
  size: number;
  constructor(partNumber: number, etag: string) {
    this.partNumber = partNumber;
    this.etag = etag;
  }
}
