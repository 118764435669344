import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  QueryList,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PrimeTemplate } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { Nullable } from 'primeng/ts-helpers';
import { KZ_COUNTRY_CODE, MASK_PHONE, PREFIX_PHONE } from 'src/const';
export type Caret = { begin: number; end: number };
export const INPUT_MASK_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputMaskComponent),
  multi: true,
};
/**
 * InputMaskComponent component is used to enter input in a certain format such as numeric, date, currency, email and phone.
 * @group Components
 */
// 21
@Component({
  selector: 'app-input-mask',
  templateUrl: './phone-input.component.html',
  host: {
    class: 'p-element',
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-inputwrapper-focus]': 'focused',
    '[class.p-inputmask-clearable]': 'showClear && !disabled',
  },
  providers: [INPUT_MASK_VALUE_ACCESSOR],
  styleUrls: ['./phone-input.component.scss'],
})
export class InputMaskComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  maskPhone = MASK_PHONE;
  prefixPhone = PREFIX_PHONE;
  _codeCountry = KZ_COUNTRY_CODE;
  numberPrefix = '+7';

  @Input() get codeCountry(): string | undefined | null {
    return this._codeCountry;
  }
  set codeCountry(val: string | undefined | null) {
    this._codeCountry = val;
    this.configDataForMask();
  }

  /**
   * HTML5 input type.
   * @group Props
   */
  @Input() type = 'text';
  /**
   * Placeholder character in mask, default is underscore.
   * @group Props
   */
  @Input() slotChar = '_';
  /**
   * Clears the incomplete value on blur.
   * @group Props
   */
  @Input() autoClear = false;
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  @Input() showClear = false;
  /**
   * Inline style of the input field.
   * @group Props
   */
  @Input() style: { [klass: string]: any } | null | undefined;
  /**
   * Identifier of the focus input to match a label defined for the component.
   * @group Props
   */
  @Input() inputId: string | undefined;
  /**
   * Style class of the input field.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Advisory information to display on input.
   * @group Props
   */
  @Input() customPlaceholder: string | undefined;
  /**
   * Size of the input field.
   * @group Props
   */
  @Input() size: number | undefined;
  /**
   * Maximum number of character allows in the input field.
   * @group Props
   */
  @Input() maxlength: number | undefined;
  /**
   * Specifies tab order of the element.
   * @group Props
   */
  @Input() tabindex: string | undefined;
  /**
   * Title text of the input text.
   * @group Props
   */
  @Input() title: string | undefined;
  /**
   * Used to define a string that labels the input element.
   * @group Props
   */
  @Input() ariaLabel: string | undefined;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  @Input() ariaLabelledBy: string | undefined;
  /**
   * Used to indicate that user input is required on an element before a form can be submitted.
   * @group Props
   */
  @Input() ariaRequired: boolean | undefined;
  /**
   * When present, it specifies that the element value cannot be altered.
   * @group Props
   */
  @Input() disabled: boolean | undefined;
  /**
   * When present, it specifies that an input field is read-only.
   * @group Props
   */
  @Input() readonly: boolean | undefined;
  /**
   * Defines if ngModel sets the raw unmasked value to bound value or the formatted mask value.
   * @group Props
   */
  @Input() unmask: boolean | undefined = true;
  /**
   * Name of the input field.
   * @group Props
   */
  @Input() name: string | undefined;
  /**
   * When present, it specifies that an input field must be filled out before submitting the form.
   * @group Props
   */
  @Input() required: boolean | undefined;
  /**
   * Regex pattern for alpha characters
   * @group Props
   */
  @Input() characterPattern = '[A-Za-z]';
  /**
   * When present, the input gets a focus automatically on load.
   * @group Props
   */
  @Input() autoFocus: boolean | undefined;
  /**
   * Used to define a string that autocomplete attribute the current element.
   * @group Props
   */
  @Input() autocomplete: string | undefined = 'off';
  /**
   * When present, it specifies that whether to clean buffer value from model.
   * @group Props
   */
  @Input() keepBuffer = false;

  /**
   * Callback to invoke when the mask is completed.
   * @group Emits
   */
  @Output() completeEvent: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Callback to invoke when the component receives focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() focusEvent: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke when the component loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() blurEvent: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke on input.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() inputEvent: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke on input key press.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() keyDownEvent: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke when input field is cleared.
   * @group Emits
   */
  @Output() clearEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('input', { static: true }) inputViewChild: Nullable<ElementRef>;

  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;

  clearIconTemplate: Nullable<TemplateRef<any>>;
  value: Nullable<string>;
  _mask: Nullable<string>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onModelChange: Function = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onModelTouched: Function = () => {};

  symbolChanged = Array(this.numberPrefix.length).fill('#').join('');
  input: Nullable<HTMLInputElement>;
  filled: Nullable<boolean>;
  defs: Nullable<{ [klass: string]: any }>;
  tests: RegExp[] | any;
  partialPosition: Nullable<number>;
  firstNonMaskPos: Nullable<number>;
  lastRequiredNonMaskPos: Nullable<number>;
  len: Nullable<number>;
  oldVal: Nullable<string>;
  buffer: string[] | any;
  defaultBuffer: Nullable<string>;
  focusText: Nullable<string>;
  caretTimeoutId: any;
  androidChrome = true;
  focused: Nullable<boolean>;
  placeholder: string | undefined;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: any,
    public el: ElementRef,
    public cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this._platformId)) {
      const ua = navigator.userAgent;
      this.androidChrome = /chrome/i.test(ua) && /android/i.test(ua);
    }
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'clearicon':
          this.clearIconTemplate = item.template;
          break;
      }
    });
  }

  get mask(): string | undefined | null {
    return this._mask;
  }
  set mask(val: string | undefined | null) {
    this._mask = val;
  }

  configDataForMask() {
    this.numberPrefix = this.prefixPhone[this.codeCountry];
    if (this.numberPrefix) {
      this.mask = Array(this.numberPrefix.length).fill('#').join('') + this.maskPhone[this.codeCountry];
      this.placeholder = (this.mask as any).replaceAll('0', '_');
      if (this.customPlaceholder) {
        this.placeholder = this.customPlaceholder;
      }
      this.symbolChanged = Array(this.numberPrefix.length).fill('#').join('');
    }
    this.initDefs();

    if (this.mask) {
      this.initMask();
      this.writeValue('');
    }
    this.onModelChange(this.value);
    this.cd.detectChanges();
  }

  initDefs() {
    this.defs = {
      '0': '[0-9]',
      a: this.characterPattern,
      '*': `${this.characterPattern}|[0-9]`,
    };
  }

  initMask() {
    this.tests = [];
    this.partialPosition = (this.mask as string)?.length;
    this.len = (this.mask as string)?.length;
    this.firstNonMaskPos = null;

    const maskTokens = (this.mask as string)?.split('');
    for (let i = 0; i < maskTokens.length; i++) {
      const c = maskTokens[i];
      if (c === '?') {
        this.len--;
        this.partialPosition = i;
      } else if (this.defs[c]) {
        this.tests.push(new RegExp(this.defs[c]));
        if (this.firstNonMaskPos === null) {
          this.firstNonMaskPos = this.tests.length - 1;
        }
        if (i < this.partialPosition) {
          this.lastRequiredNonMaskPos = this.tests.length - 1;
        }
      } else {
        this.tests.push(null);
      }
    }

    this.buffer = [];
    for (let i = 0; i < maskTokens.length; i++) {
      const c = maskTokens[i];
      if (c !== '?') {
        if (this.defs[c]) this.buffer.push(this.getPlaceholder(i));
        else this.buffer.push(c);
      }
    }

    this.defaultBuffer = this.buffer.join('');
  }

  writeValue(value: any): void {
    this.value = value;
    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      if (this.value === undefined || this.value === null) this.inputViewChild.nativeElement.value = '';
      else this.inputViewChild.nativeElement.value = this.value;

      this.checkVal();
      this.focusText = this.inputViewChild.nativeElement.value;
      this.updateFilledState();
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  caret(first?: number, last?: number): Caret | undefined {
    let range, begin, end;

    if (!this.inputViewChild?.nativeElement.offsetParent || this.inputViewChild.nativeElement !== this.inputViewChild.nativeElement.ownerDocument.activeElement) {
      return;
    }

    if (typeof first === 'number') {
      begin = first;
      end = typeof last === 'number' ? last : begin;
      if (this.inputViewChild.nativeElement.setSelectionRange) {
        this.inputViewChild.nativeElement.setSelectionRange(end, end);
      } else if (this.inputViewChild.nativeElement['createTextRange']) {
        range = this.inputViewChild.nativeElement['createTextRange']();
        range.collapse(true);
        range.moveEnd('character', end);
        range.moveStart('character', begin);
        range.select();
      }
    } else {
      if (this.inputViewChild.nativeElement.setSelectionRange) {
        begin = this.inputViewChild.nativeElement.selectionStart;
        end = this.inputViewChild.nativeElement.selectionEnd;
      } else if ((this._document as any['selection']) && (this._document as any)['selection'].createRange) {
        range = (this._document as any['selection']).createRange();
        begin = 0 - range.duplicate().moveStart('character', -100000);
        end = begin + range.text.length;
      }

      return { begin: begin, end: end };
    }
  }

  isCompleted(): boolean {
    for (let i = this.firstNonMaskPos as number; i <= (this.lastRequiredNonMaskPos as number); i++) {
      if (this.tests[i] && (this.buffer as string[])[i] === this.getPlaceholder(i)) {
        return false;
      }
    }

    return true;
  }

  getPlaceholder(i: number) {
    if (i < this.slotChar.length) {
      return this.slotChar.charAt(i);
    }
    return this.slotChar.charAt(0);
  }

  seekNext(pos: number) {
    while (++pos < (this.len as number) && !this.tests[pos]);
    return pos;
  }

  seekPrev(pos: number) {
    while (--pos >= 0 && !this.tests[pos]);
    return pos;
  }

  shiftL(begin: number, end: number) {
    let i, j;

    if (begin < 0) {
      return;
    }

    for (i = begin, j = this.seekNext(end); i < (this.len as number); i++) {
      if (this.tests[i]) {
        if (j < (this.len as number) && this.tests[i].test(this.buffer[j])) {
          this.buffer[i] = this.buffer[j];
          this.buffer[j] = this.getPlaceholder(j);
        } else {
          break;
        }

        j = this.seekNext(j);
      }
    }
    this.writeBuffer();
    this.caret(Math.max(this.firstNonMaskPos as number, begin));
  }

  shiftR(pos: number) {
    let i, c, j, t;

    for (i = pos, c = this.getPlaceholder(pos); i < (this.len as number); i++) {
      if (this.tests[i]) {
        j = this.seekNext(i);
        t = this.buffer[i];
        this.buffer[i] = c;
        if (j < (this.len as number) && this.tests[j].test(t)) {
          c = t;
        } else {
          break;
        }
      }
    }
  }

  handleAndroidInput(e: Event) {
    const curVal = this.inputViewChild?.nativeElement.value.replace(this.numberPrefix, this.symbolChanged);
    const pos = this.caret() as Caret;
    if (this.oldVal && this.oldVal.length && this.oldVal.length > curVal.length) {
      // a deletion or backspace happened
      this.checkVal(true);
      while (pos.begin > 0 && !this.tests[pos.begin - 1]) pos.begin--;
      if (pos.begin === 0) {
        while (pos.begin < (this.firstNonMaskPos as number) && !this.tests[pos.begin]) pos.begin++;
      }

      setTimeout(() => {
        this.caret(pos.begin, pos.begin);
        this.updateModel(e);
        if (this.isCompleted()) {
          this.completeEvent.emit();
        }
      }, 0);
    } else {
      this.checkVal(true);
      while (pos.begin < (this.len as number) && !this.tests[pos.begin]) pos.begin++;

      setTimeout(() => {
        this.caret(pos.begin, pos.begin);
        this.updateModel(e);
        if (this.isCompleted()) {
          this.completeEvent.emit();
        }
      }, 0);
    }
  }

  onInputBlur(e: Event) {
    this.focused = false;
    this.onModelTouched();
    if (!this.keepBuffer) {
      this.checkVal();
    }
    this.updateFilledState();
    this.blurEvent.emit(e);

    if (this.inputViewChild?.nativeElement.value !== this.focusText || this.inputViewChild?.nativeElement.value !== this.value) {
      this.updateModel(e);
      const event = this._document.createEvent('HTMLEvents');
      event.initEvent('change', true, false);
      this.inputViewChild?.nativeElement.dispatchEvent(event);
    }
  }

  onInputKeydown(e: KeyboardEvent) {
    if (this.readonly) {
      return;
    }

    const k = e.which || e.keyCode;
    let pos;
    let begin;
    let end;
    let iPhone;
    if (isPlatformBrowser(this._platformId)) {
      iPhone = /iphone/i.test(DomHandler.getUserAgent());
    }
    this.oldVal = this.inputViewChild?.nativeElement.value.replace(this.numberPrefix, this.symbolChanged);

    this.keyDownEvent.emit(e);

    //backspace, delete, and escape get special treatment
    if (k === 8 || k === 46 || (iPhone && k === 127)) {
      pos = this.caret() as Caret;
      begin = pos.begin;
      end = pos.end;

      if (end - begin === 0) {
        begin = k !== 46 ? this.seekPrev(begin) : (end = this.seekNext(begin - 1));
        end = k === 46 ? this.seekNext(end) : end;
      }

      this.clearBuffer(begin, end);
      if (this.keepBuffer) {
        this.shiftL(begin, end - 2);
      } else {
        this.shiftL(begin, end - 1);
      }
      this.updateModel(e);
      this.inputEvent.emit(e);

      e.preventDefault();
    } else if (k === 13) {
      // enter
      this.onInputBlur(e);
      this.updateModel(e);
    } else if (k === 27) {
      // escape
      (this.inputViewChild as ElementRef).nativeElement.value = this.focusText;
      this.caret(0, this.checkVal());
      this.updateModel(e);

      e.preventDefault();
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (this.readonly) {
      return;
    }

    const k = e.which || e.keyCode;
    const pos = this.caret() as Caret;
    let p: number;
    let c: string;
    let next: number;
    let completed!: boolean;

    if (e.ctrlKey || e.altKey || e.metaKey || k < 32 || (k > 34 && k < 41)) {
      return;
    } else if (k && k !== 13) {
      if (pos.end - pos.begin !== 0) {
        this.clearBuffer(pos.begin, pos.end);
        this.shiftL(pos.begin, pos.end - 1);
      }

      p = this.seekNext(pos.begin - 1);
      if (p < (this.len as number)) {
        c = String.fromCharCode(k);
        if (this.tests[p].test(c)) {
          this.shiftR(p);

          this.buffer[p] = c;
          this.writeBuffer();
          next = this.seekNext(p);

          if (DomHandler.isClient() && /android/i.test(DomHandler.getUserAgent())) {
            const proxy = () => {
              this.caret(next);
            };

            setTimeout(proxy, 0);
          } else {
            this.caret(next);
          }

          if (pos.begin <= (this.lastRequiredNonMaskPos as number)) {
            completed = this.isCompleted();
          }

          this.inputEvent.emit(e);
        }
      }
      e.preventDefault();
    }

    this.updateModel(e);

    this.updateFilledState();

    if (completed) {
      this.completeEvent.emit();
    }
  }

  clearBuffer(start: number, end: number) {
    if (!this.keepBuffer) {
      let i;
      for (i = start; i < end && i < (this.len as number); i++) {
        if (this.tests[i]) {
          this.buffer[i] = this.getPlaceholder(i);
        }
      }
    }
  }

  writeBuffer() {
    (this.inputViewChild as ElementRef).nativeElement.value = this.buffer.join('').replace(this.symbolChanged, this.numberPrefix);
  }

  checkVal(allow?: boolean): number {
    //try to place characters where they belong
    const test = this.inputViewChild?.nativeElement.value.replace(this.numberPrefix, this.symbolChanged);
    let lastMatch;
    let i;
    let c;
    let pos;
    for (i = 0, pos = 0; i < (this.len as number); i++) {
      if (this.tests[i]) {
        this.buffer[i] = this.getPlaceholder(i);
        while (pos++ < test.length) {
          c = test.charAt(pos - 1);
          if (this.tests[i].test(c)) {
            if (!this.keepBuffer) {
              this.buffer[i] = c;
            }
            lastMatch = i;
            break;
          }
        }
        if (pos > test.length) {
          this.clearBuffer(i + 1, this.len as number);
          break;
        }
      } else {
        if (this.buffer[i] === test.charAt(pos)) {
          pos++;
        }
        if (i < (this.partialPosition as number)) {
          lastMatch = i;
        }
      }
    }
    if (allow) {
      this.writeBuffer();
    } else if (lastMatch + 1 < (this.partialPosition as number)) {
      if (this.autoClear || this.buffer.join('') === this.defaultBuffer) {
        // Invalid value. Remove it and replace it with the
        // mask, which is the default behavior.
        if (this.inputViewChild?.nativeElement.value) this.inputViewChild.nativeElement.value = '';
        this.clearBuffer(0, this.len as number);
      } else {
        // Invalid value, but we opt to show the value to the
        // user and allow them to correct their mistake.
        this.writeBuffer();
      }
    } else {
      this.writeBuffer();
      (this.inputViewChild as ElementRef).nativeElement.value = this.inputViewChild?.nativeElement.value.substring(0, lastMatch + 1);
    }

    return (this.partialPosition ? i : this.firstNonMaskPos) as number;
  }

  onInputFocus(event: Event) {
    if (this.readonly) {
      return;
    }

    this.focused = true;

    clearTimeout(this.caretTimeoutId);
    this.focusText = this.inputViewChild?.nativeElement.value;
    const pos = this.keepBuffer ? this.inputViewChild?.nativeElement.value.length : this.checkVal();
    this.caretTimeoutId = setTimeout(() => {
      if (this.inputViewChild?.nativeElement !== this.inputViewChild?.nativeElement.ownerDocument.activeElement) {
        return;
      }
      this.writeBuffer();
      if (pos === this.mask?.replace('?', '').length) {
        this.caret(0, pos);
      } else {
        this.caret(pos);
      }
    }, 10);

    this.focusEvent.emit(event);
  }

  onInputChange(event: Event) {
    if (this.androidChrome) this.handleAndroidInput(event);
    else this.handleInputChange(event);

    this.inputEvent.emit(event);
  }

  handleInputChange(event: Event) {
    if (this.readonly) {
      return;
    }

    setTimeout(() => {
      const pos = this.checkVal(true);
      this.caret(pos);
      this.updateModel(event);
      if (this.isCompleted()) {
        this.completeEvent.emit();
      }
    }, 0);
  }

  getUnmaskedValue() {
    const unmaskedBuffer = [];
    for (let i = 0; i < this.buffer.length; i++) {
      const c = this.buffer[i];
      if (this.tests[i] && c !== this.getPlaceholder(i)) {
        unmaskedBuffer.push(c);
      }
    }

    return unmaskedBuffer.join('');
  }

  updateModel(e: Event) {
    const updatedValue = this.unmask ? this.getUnmaskedValue() : (e.target as HTMLInputElement).value;
    if (updatedValue !== null || updatedValue !== undefined) {
      this.value = updatedValue;
      this.onModelChange(this.value);
    }
  }

  updateFilledState() {
    this.filled = this.inputViewChild?.nativeElement && this.inputViewChild.nativeElement.value !== '';
  }

  focus() {
    this.inputViewChild?.nativeElement.focus();
  }

  clear() {
    (this.inputViewChild as ElementRef).nativeElement.value = '';
    this.value = null;
    this.onModelChange(this.value);
    this.clearEvent.emit();
  }
}
