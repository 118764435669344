import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { language } from '@environments/language';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as models from './staff.model';

@Injectable({ providedIn: 'root' })
export class StaffService {
  #filterUserListEventSubject = new BehaviorSubject<models.GetFilteredUserListBody>({ pageNumber: 0, pageSize: 10, direction: 'DESC', sortBy: 'id' });
  public filterUserListEvent$ = this.#filterUserListEventSubject.asObservable();

  #filterUserInactiveListEventSubject = new BehaviorSubject<models.GetFilteredUserListBody>({ isActive: false, pageNumber: 0, pageSize: 10, direction: 'DESC', sortBy: 'id' });
  public filterUserInactiveListEvent$ = this.#filterUserInactiveListEventSubject.asObservable();

  #clearFilters = new Subject();
  public clearFilters$ = this.#clearFilters.asObservable();

  constructor(private http: HttpClient) {}

  public fireFilterUserListEvent(filter: models.GetFilteredUserListBody = { pageNumber: 0, pageSize: 10, direction: 'DESC', sortBy: 'id' }): void {
    this.#filterUserListEventSubject.next(filter);
  }

  public fireFilterInactiveUserListEvent(filter: models.GetFilteredUserListBody = { isActive: false, pageNumber: 0, pageSize: 10, direction: 'DESC', sortBy: 'id' }): void {
    this.#filterUserInactiveListEventSubject.next(filter);
  }

  public getUserById(id: models.GetUserByIdParams): Observable<models.GetUserByIdResp> {
    return this.http.get<models.GetUserByIdResp>(`${environment.apiUserManagerUrl}/api/users/${id}`);
  }

  public getRoleList(): Observable<models.GetRoleListResp> {
    let params = new HttpParams();
    params = params.append('locale', String(language.language as models.GetRoleListQueryParams));
    return this.http.get<models.GetRoleListResp>(`${environment.apiRoleManagerUrl}/roles`, { params });
  }

  public getOrganizationById(id: number): Observable<models.getOrganizationByIdResponse> {
    return this.http.get<any>(`${environment.apiUserManagerUrl}/api/organizations/${id}`);
  }

  public getOrganizationList(): Observable<models.GetOrganizationListResp> {
    return this.http.get<models.GetOrganizationListResp>(`${environment.apiUserManagerUrl}/api/organizations/getAll`);
  }

  public getAchievementById(id: models.GetAchievementByIdParams): Observable<models.GetAchievementByIdResp> {
    return this.http.get<models.GetAchievementByIdResp>(`${environment.apiUserManagerUrl}/api/achievements/${id}`);
  }

  public getAchievementsList(): Observable<models.GetAchievementResp> {
    return this.http.get<models.GetAchievementResp>(`${environment.apiUserManagerUrl}/api/achievements/list`);
  }

  public getAchievement(agentId: models.GetAchievementByAgentIdParams): Observable<models.GetAchievementByAgentIdResp> {
    return this.http.get<models.GetAchievementByAgentIdResp>(`${environment.apiUserManagerUrl}/api/user-achievement/${agentId}`);
  }

  public createAchievement(agentId: models.CreateAchievementParams['agentId'], achievementId: models.CreateAchievementParams['achievementIdList']): Observable<models.CreateAchievementResp> {
    return this.http.post<models.CreateAchievementResp>(`${environment.apiUserManagerUrl}/api/user-achievement/${agentId}/${achievementId}`, {});
  }

  public deleteAchievement(agentId: models.DeleteAchievementParams['agentId']): Observable<models.DeleteAchievementResp> {
    return this.http.delete<models.DeleteAchievementResp>(`${environment.apiUserManagerUrl}/api/user-achievement/${agentId}`);
  }

  public getGroupList(): Observable<models.GetGroupResp> {
    return this.http.get<models.GetGroupResp>(`${environment.apiUserManagerUrl}/api/users/role/7/infos`);
  }

  public getGroupListByLocation(obj) {
    let params = new HttpParams().set('roleIds', obj.roleIds).set('searchData', obj.searchData);
    if (obj.locationCodes && obj.locationCodes.length) {
      obj.locationCodes.forEach((locationCode) => {
        params = params.append('locationCodes', locationCode);
      });
    }
    return this.http.post<models.GetGroupResp>(`${environment.apiUserManagerUrl}/open-api/agents/agentsByLocation`, params);
  }

  public getUserListByFilter(obj: models.GetFilteredUserListBody): Observable<models.GetFilteredUserListResp> {
    return this.http.post<models.GetFilteredUserListResp>(`${environment.apiUserManagerUrl}/api/users/filter`, obj);
  }

  public getCategoriesList(): Observable<models.GetCategoryListResp> {
    return this.http.get<models.GetCategoryListResp>(`${environment.apiUserManagerUrl}/api/agent-categories/list`);
  }

  public getCategory(agentId: models.GetCategoryByAgentIdParams): Observable<models.GetCategoryByAgentIdResp> {
    return this.http.get<models.GetCategoryByAgentIdResp>(`${environment.apiUserManagerUrl}/api/user-agent-category/${agentId}`, {});
  }

  public createCategory(obj): Observable<models.CreateCategoryResp> {
    return this.http.post<models.CreateCategoryResp>(`${environment.apiUserManagerUrl}/api/user-agent-category`, obj);
  }

  public deleteCategory(body: models.DeleteCategoryParams): Observable<models.DeleteCategoryResp> {
    return this.http.delete<models.DeleteCategoryResp>(`${environment.apiUserManagerUrl}/api/user-agent-category`, { body, responseType: 'json' });
  }

  public createUser(obj: models.CreateUserParams): Observable<models.CreateUserResp> {
    return this.http.post<models.CreateUserResp>(`${environment.apiUserManagerUrl}/api/users`, obj);
  }

  public updateUser(obj: models.UpdateUserParams): Observable<models.UpdateUserResp> {
    return this.http.put<models.UpdateUserResp>(`${environment.apiUserManagerUrl}/api/users`, obj);
  }

  public updatePasswordById(id: models.UpdateUserPasswordParams['path']['id'], password: models.UpdateUserPasswordParams['body']['password']): Observable<models.UpdateUserPasswordResp> {
    return this.http.put<models.UpdateUserPasswordResp>(`${environment.apiUserManagerUrl}/api/users/${id}/pass`, password);
  }

  public clearFilters() {
    this.#clearFilters.next(null);
  }
}
