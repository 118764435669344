import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StaffService } from '@modules/staff/staff.service';
import { ReplaySubject, Subject, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs';
@Component({
  selector: 'app-groups-select',
  templateUrl: './groups-select.component.html',
  styleUrls: ['./groups-select.component.scss'],
})
export class GroupsSelectComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  @Input() isDisabled = false;
  @Input() locationCodes: string[] = [];
  @Input() groupName = null;
  @Input() initialLoad = false;
  @Input() selectedValue: number | string = null;
  @Input() roleIds = [7];
  @Input() bindValue = 'personId';
  @Input() placeholder = 'Выберите группу';
  @Output() selected = new EventEmitter<string[]>();
  filterForm: FormGroup;
  loading = false;
  groupsList = [];

  #groupsSearchTermSubject: ReplaySubject<string> = null;
  constructor(
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _staffService: StaffService,
  ) {}

  ngOnInit() {
    this.filterForm = this._fb.group({
      searchData: this._fb.control(''),
      locationCodes: this._fb.control(this.locationCodes),
      roleIds: this._fb.control(this.roleIds),
    });

    if (this.initialLoad) {
      this.loadGroups();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.locationCodes?.firstChange && changes.locationCodes?.previousValue !== changes.locationCodes?.currentValue) {
      this.filterForm.patchValue({
        locationCodes: this.locationCodes,
        searchData: '',
      });
      this.groupsList = [];
    }
  }

  onOpen() {
    if (!this.groupsList.length) {
      this.loadGroups();
    }
  }

  onChange(event): void {
    this.selected.emit(event);
  }

  loadGroups() {
    this.loading = true;
    this._staffService
      .getGroupListByLocation(this.filterForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (groups) => {
          this.groupsList = groups.data;
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
  }

  onSearch(event) {
    if (this.#groupsSearchTermSubject === null) {
      this.#groupsSearchTermSubject = new ReplaySubject<string>();
      this.loading = true;
      this.#groupsSearchTermSubject
        .pipe(
          debounceTime(400),
          filter((term) => term !== null),
          map((term: string) => term.trim()),
          distinctUntilChanged(),
          switchMap((searchData) => this.fetchGroupsParams(searchData)),
          takeUntil(this.destroy$),
        )
        .subscribe(
          ({ data }: any) => {
            this.groupsList = data;
            this.loading = false;
            this._cdr.detectChanges();
          },
          () => {
            this.loading = false;
          },
        );
    }
    this.#groupsSearchTermSubject.next(event.term);
  }

  fetchGroupsParams(searchData: string) {
    this.filterForm.patchValue({
      searchData,
    });
    return this._staffService.getGroupListByLocation(this.filterForm.value);
  }

  returnTrue(): boolean {
    return true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
