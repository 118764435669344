import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  constructor(
    public modalRef: BsModalRef,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.ngxLoader.stop();
  }

  close() {
    this.router.navigateByUrl('login');
    this.modalRef.hide();
  }
}
