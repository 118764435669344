import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import * as sharedModels from '@modules/shared/models';
import { Util } from '@modules/shared/services';

@Component({
  template: '{{ value }}',
  selector: 'app-name',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameComponent implements OnInit, OnChanges {
  @Input()
  public readonly name: sharedModels.Localization | any = null;

  public value: string = null;

  constructor(private settingsService: Util) {}

  ngOnInit() {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    if (this.name) {
      this.value = this.name?.[currentLanguageAsKey];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name.previousValue !== changes.name.currentValue) {
      const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
      if (this.name) {
        this.value = this.name?.[currentLanguageAsKey];
      }
    }
  }
}
