import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { GetUserInfoResp } from '@modules/shared/models';
import * as Stomp from '@stomp/stompjs';
import { BehaviorSubject, Observable } from 'rxjs';
import SockJS from 'sockjs-client';

declare let $: any; // not required

@Injectable({
  providedIn: 'root',
})
export class NotificationsUtil {
  private stompClient = null;
  private webSocketConnection = false;
  private tryToConnectSec = 15;
  public newNotificationSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public getNotificationCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private videoSetSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public getVideoUrl(): Observable<string> {
    return this.videoSetSubject.asObservable();
  }

  private setVideoUrl(videoUrl: string): void {
    this.videoSetSubject.next(videoUrl);
  }

  private setWebSocketConnected(connected: boolean) {
    this.webSocketConnection = connected;
  }

  public webSocketConnect(currentUser: GetUserInfoResp) {
    if (this.webSocketConnection) {
      return;
    }
    if (currentUser) {
      // this.getNotifCount();
      const socket = new SockJS(environment.apiNotificationManagerUrl + '/open-api/stomp-endpoint');
      this.stompClient = Stomp.Stomp.over(socket);

      const local = this;
      this.stompClient.connect(
        {},
        () => {
          local.setWebSocketConnected(true);
          local.stompClient.subscribe(
            '/user/' + currentUser.login.toLocaleLowerCase() + '/topic/notification',
            (hello) => {
              const newNotification = JSON.parse(hello.body);
              const NotificationOptions = {
                applicationId: newNotification.applicationId,
                body: newNotification.message.replace(/<[^>]+>/g, ''),
                data: newNotification,
                da: hello.body,
                icon: 'assets/images/jurta.png',
              };
              const notification = new Notification('Уведомление', NotificationOptions);
              this.setVideoUrl(notification.data.data);
              this.newNotificationSubject$.next(notification);
              local.showNotification();
              // local.getNotifCount();
            },
            () => {
              local.tryToConnectWebSocketAfter(currentUser, local.tryToConnectSec);
            },
          );
        },
        () => {
          local.tryToConnectWebSocketAfter(currentUser, local.tryToConnectSec);
        },
      );
    }
  }

  public webSocketDisconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
    this.setWebSocketConnected(false);
  }

  // public getNotifCount() {
  //   this.notificationService.getAllNotOpenedNotificationCount()
  //     .subscribe(res => {
  //       if (res.data > 0) {
  //         $('#menu-notification-count').show();
  //       } else {
  //         $('#menu-notification-count').hide();
  //       }
  //       $('#menu-notification-count').html(res.data);
  // this.getNotificationCount$.next(res.data);
  //     }, err => {
  //       this.notification.showError(err, 'Ошибка');
  //     });
  // }

  private tryToConnectWebSocketAfter(currentUser: GetUserInfoResp, sec: number) {
    this.setWebSocketConnected(false);
    const local = this;
    console.log('WebSocket disconnected try connect after ' + sec + ' sec');
    setTimeout(() => {
      local.webSocketConnect(currentUser);
    }, sec * 1000);
  }

  private showNotification() {
    $('#show-new-notification').show();
    setTimeout(() => {
      $('#show-new-notification').hide();
    }, 3000);
  }
}
