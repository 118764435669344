<div class="video-block">

  <div class="modal-header">
    <span class="title">Видеообзор</span>
    <button type="button" class="close" (click)="onClose()">
      <span aria-hidden="true">
        <i class="pi pi-times"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <app-video-player
      [prefix]="'claim'"
      [id]="applicationId"
      [source]="videoUrl"
      [playVideo]="true">
    </app-video-player>
  </div>

</div>
