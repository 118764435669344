import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NsSpinnerComponent } from './components/ns-spinner/ns-spinner.component';

@NgModule({
  declarations: [NsSpinnerComponent],
  imports: [CommonModule],
  exports: [NsSpinnerComponent],
})
export class SpinnerModule {}
