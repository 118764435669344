import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as models from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly http: HttpClient) {}

  public findUserByLogin(): Observable<models.GetUserInfoResp> {
    return this.http.get<models.GetUserInfoResp>(`${environment.apiUserManagerUrl}/api/users/info`);
  }

  public getUserListByFilter(obj: models.GetUserListByFilterParams): Observable<models.GetUserListByFilterResp> {
    return this.http.post<models.GetUserListByFilterResp>(`${environment.apiUserManagerUrl}/api/users/filter`, obj);
  }

  public findAgentByLogin(login: string): Observable<models.GetUserInfoResp> {
    return this.http.get<models.GetUserInfoResp>(`${environment.apiUserManagerUrl}/api/users/info?locale=RU&login=${login}`);
  }

  public getAgents(): Observable<any> {
    return this.http.get<any>(`${environment.apiUserManagerUrl}/api/agents`);
  }

  public getAgentsToAssign(): Observable<models.GetAgentListToAssignResp> {
    return this.http.get<models.GetAgentListToAssignResp>(`${environment.apiViewManagerUrl}/api/agents/getAgentsToAssign`);
  }

  public getAgentByPageable(obj): Observable<any> {
    return this.http.post<any>(`${environment.apiUserManagerUrl}/api/agents/list`, obj);
  }

  public getAgentByPageableAsync(obj): Observable<any> {
    return this.http.post<any>(`${environment.apiUserManagerUrl}/api/agents/list`, obj);
  }

  public createUserClient(dto: models.CreateUserParams): Observable<models.CreateUserResp> {
    return this.http.post<models.CreateUserResp>(`${environment.apiUserManagerUrl}/api/profile-client`, dto);
  }

  public async createUserClientAsync(dto: any) {
    return this.http.post<any>(`${environment.apiUserManagerUrl}/api/profile-client`, dto).toPromise();
  }

  public getAllUsers(): Observable<any> {
    return this.http.get<any>(`${environment.apiUserManagerUrl}/open-api/users/getAll`);
  }

  public getTopFiveAgents(): Observable<any> {
    return this.http.get<any>(`${environment.apiUserManagerUrl}/api/agents/topFiveAgents`);
  }

  public clientRegistration(login): Observable<any> {
    return this.http.get(`${environment.apiUserManagerUrl}/api/users/clientRegistration/${login}`, { responseType: 'text' });
  }

  public createReferralCode(userId: number): Observable<any> {
    return this.http.post(`${environment.apiUserManagerUrl}/api/users/getReferralCode?userId=${userId}`, {}, { responseType: 'text' });
  }

  public getReferralLink(): Observable<any> {
    return this.http.get(`${environment.apiDataManagerUrl}/api/settings/getValue/referral_url`);
  }

  public getUserList(options: models.UserListParams): Observable<models.UserListResp> {
    return this.http.post<models.UserListResp>(`${environment.apiUserManagerUrl}/api/users/list`, options);
  }

  public updateClientById(appId: models.UpdateClientParams['query']['appId'], form: models.UpdateClientParams['body']['profile']): Observable<models.UpdateClientResp> {
    return this.http.put<models.UpdateClientResp>(`${environment.apiUserManagerUrl}/api/profile-client/crm?appId=${appId}`, form);
  }

  public getMyLastLogin(): Observable<models.GetMyLastLoginResponse> {
    return this.http.get(`${environment.apiUserManagerUrl}/api/users/getMyLastLogin`);
  }
}
