import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { language } from '@environments/language';
import { RoleFilterResult, RoleFilter, RoleFormDto } from '../models/role';

import * as models from '../models';

@Injectable({
  providedIn: 'root',
})
export class RoleManagerService {
  constructor(private http: HttpClient) {}

  getOperations(): Observable<any> {
    return this.http.get<any>(`${environment.apiRoleManagerUrl}/operations?locale=${language.language}`);
  }

  getCheckOperationList(params: any): Observable<any> {
    return this.http.get<any>(`${environment.apiRoleManagerUrl}/operations/check`, { params });
  }

  readUser(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiRoleManagerUrl}/roles/${id}`);
  }

  public getRoles(): Observable<models.GetRoleListResp> {
    return this.http.get<models.GetRoleListResp>(`${environment.apiRoleManagerUrl}/roles?locale=${language.language as models.GetRoleListParams}`);
  }

  getRolesForManage(obj: RoleFilter): Observable<RoleFilterResult> {
    return this.http.post<RoleFilterResult>(`${environment.apiRoleManagerUrl}/roles/filter?locale=${language.language}`, obj);
  }

  createRole(obj: RoleFormDto): Observable<RoleFormDto> {
    return this.http.post<RoleFormDto>(`${environment.apiRoleManagerUrl}/roles/`, obj);
  }

  readRole(id: number): Observable<RoleFormDto> {
    return this.http.get<RoleFormDto>(`${environment.apiRoleManagerUrl}/roles/${id}`);
  }

  updateRole(obj: RoleFormDto, id: number): Observable<RoleFormDto> {
    return this.http.put<RoleFormDto>(`${environment.apiRoleManagerUrl}/roles/${id}`, obj);
  }

  deleteRole(id: number): Observable<RoleFormDto> {
    return this.http.delete<RoleFormDto>(`${environment.apiRoleManagerUrl}/roles/${id}`);
  }
}
