import { AddressTypeEnum } from '@modules/shared/models/address-type.enum';

/**
 * DTO фильтрации улицы
 */
export class StreetFilterDto {
  public addressTypeId = AddressTypeEnum.District;
  public direction = 'ASC'; // Направление сортировки
  public pageNumber = 0; // Номер страницы
  public pageSize = 30; // Кол-во элементов на странице
  public parentCode: string | string[]; // Родительский код
  public sortBy = 'nameRu'; // Столбец сортировки
  public text?: string; // Текст фильтрации
}
