import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs';
import { UserListItem } from '../user-select/user-select.component';
import { AgentListByGroupManagerIdsApiService } from '../../../../big-scary/components/claims/reassign-claim-dialog/agent-list-by-group-manager-ids-api.service';
@Component({
  selector: 'app-agent-select',
  templateUrl: './agent-select.component.html',
  styleUrls: ['./agent-select.component.scss'],
})
export class AgentSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedValue = null;
  @Input() userList: UserListItem[] = [];
  @Input() locationCode = [];
  @Input() roleIds = [];
  @Input() initialLoad = false;
  @Input() bindValue: 'id' | 'login' = 'login';
  @Input() placeholder = 'Агент';
  @Input() multiple = false;
  @Input() groupsIds = [];
  @Input() isDisabled = false;
  @Input() groupName = null;

  @Output() selected = new EventEmitter<string[]>();

  bindLabel = 'agentName';
  loading = false;
  filterForm: FormGroup;
  size = 0;
  #userSearchTermSubject: ReplaySubject<string> = null;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _agentListByGroupManagerByIdsApiService: AgentListByGroupManagerIdsApiService,
  ) {}

  ngOnInit() {
    this.filterForm = this._fb.group({
      groupIds: this._fb.control(this.groupsIds),
      pageNumber: this._fb.control(0),
      searchData: this._fb.control(null),
      pageSize: this._fb.control(10),
      locationCodes: this._fb.control(this.locationCode),
      roleIds: this._fb.control(this.roleIds),
    });

    if (this.initialLoad) {
      this.loadAgents();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.groupsIds?.firstChange && changes.groupsIds?.previousValue !== changes.groupsIds?.currentValue) {
      this.filterForm.patchValue({
        groupIds: this.groupsIds,
        pageNumber: 0,
      });
      this.userList = [];
    }

    if (!changes.locationCode?.firstChange && changes.locationCode?.previousValue !== changes.locationCode?.currentValue) {
      this.filterForm.patchValue({
        locationCodes: this.locationCode,
        pageNumber: 0,
      });
      this.userList = [];
    }
  }

  onOpen() {
    this.filterForm.patchValue({
      pageNumber: 0,
    });
    if (!this.userList.length) {
      this.loadAgents();
    }
  }

  loadAgents() {
    this.loading = true;
    this._agentListByGroupManagerByIdsApiService
      .fetchAgentsParams(this.filterForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: any) => {
        this.userList = this.userList.concat(data.data.data);
        this.size = data.size;
        this.loading = false;
        this._cdr.detectChanges();
      });
  }

  onChange(event): void {
    if (event) {
      this.selected.emit(event);
    }
  }

  onSearch(event) {
    if (this.#userSearchTermSubject === null) {
      this.#userSearchTermSubject = new ReplaySubject<string>();
      this.loading = true;
      this.#userSearchTermSubject
        .pipe(
          debounceTime(400),
          filter((term) => term !== null),
          map((term: string) => term.trim()),
          distinctUntilChanged(),
          switchMap((searchData) => this.fetchAgentsParams(searchData)),
          takeUntil(this.destroy$),
        )
        .subscribe(({ data }: any) => {
          this.userList = data.data.data;
          this.loading = false;
          this._cdr.detectChanges();
        });
    }
    this.#userSearchTermSubject.next(event.term);
  }

  fetchAgentsParams(searchData: string) {
    this.filterForm.patchValue({
      pageNumber: 0,
      searchData,
    });
    return this._agentListByGroupManagerByIdsApiService.fetchAgentsParams(this.filterForm.value);
  }

  onNextPage() {
    if (this.size !== this.filterForm.value.pageNumber) {
      this.filterForm.patchValue({
        pageNumber: this.filterForm.value.pageNumber + 1,
      });
      this.loadAgents();
    }
  }

  clear(): void {
    this.multiple === true ? this.selected.emit([]) : this.selected.emit(null);
    this.userList = [];
    this.filterForm.patchValue({
      searchData: null,
    });
  }

  closeSelect() {
    if (this.filterForm.value.searchData) {
      this.filterForm.patchValue({
        searchData: null,
      });
      this.userList = [];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
