<div [class]="className">
  <ng-container *ngIf="label">
    <label [for]="labelForId">
      <span class="label">{{label}}
        <ng-container *ngIf="required">
          <span class="text-danger">*</span>
        </ng-container>
      </span>
      <span *ngIf="labelAdditionalText" class="label-add">{{ labelAdditionalText }}</span>
      <ng-content select="[labelAdditional]"></ng-content>
    </label>
  </ng-container>
  <ng-content></ng-content>
</div>
