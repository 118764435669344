import { KZ_COUNTRY_CODE, UZ_COUNTRY_CODE } from 'src/const';

export function getCodeByNumber(number: string) {
  let countryCode = KZ_COUNTRY_CODE;
  switch (number?.length) {
    case 10:
      countryCode = KZ_COUNTRY_CODE;
      break;
    case 9:
      countryCode = UZ_COUNTRY_CODE;
      break;
  }
  return countryCode;
}
