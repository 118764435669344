import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as models from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  public getAllPageable(params: models.NotificationListParams): Observable<models.NotificationListResp> {
    return this.http.post(`${environment.apiNotificationManagerUrl}/api/notification/getAllPageable`, params);
  }

  public getAllNotOpenedNotificationCount(): Observable<{ success: boolean; data: number }> {
    return this.http.get<{ success: boolean; data: number }>(`${environment.apiNotificationManagerUrl}/api/notification/all-not-opened/count`);
  }

  public opened(id): Observable<any> {
    return this.http.put(`${environment.apiNotificationManagerUrl}/api/notification/opened/` + id, {});
  }

  public getAllNotificationType(): Observable<any> {
    return this.http.get(`${environment.apiNotificationManagerUrl}/api/notification-type/getAll`);
  }

  public saveNotification(obj): Observable<any> {
    return this.http.post(`${environment.apiNotificationManagerUrl}/api/notification`, obj);
  }
}
