import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemDictionariesResolver } from './resolvers/system-dictionaries.resolver';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('@modules/login/login.module').then((m: typeof import('@modules/login/login.module')) => m.LoginModule),
  },
  {
    path: '',
    resolve: { dictionaries: SystemDictionariesResolver },
    loadChildren: () => import('./main/main.module').then((m: typeof import('./main/main.module')) => m.MainModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
