import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreakpointService } from '@modules/shared/services/breakpoint.service';
import { LoginService } from '@modules/shared/services/login.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { COUNTRIES, COUNTRY_SELECT } from 'src/const';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('loginInput', { static: true }) public loginInput: ElementRef;

  public errorMessage$: Observable<string>;
  public isLoading$: Observable<boolean>;
  public countries: COUNTRY_SELECT[] = COUNTRIES;
  public selectedCountry: COUNTRY_SELECT = null;
  public form: FormGroup;
  public isMobile = false;
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _fb: FormBuilder,
    private _breakpointService: BreakpointService,
    private _loginService: LoginService,
  ) {}

  public ngOnInit(): void {
    this._breakpointService
      .isMobile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      });

    this.form = this._fb.group({
      username: this._fb.control(null, Validators.required),
      password: this._fb.control(null, Validators.required),
    });

    this.errorMessage$ = this._loginService.errorMessage$;
    this.isLoading$ = this._loginService.isLoading$;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (Boolean(changes) && Boolean(changes.errorMessage) && Boolean(changes.errorMessage.currentValue) && this.form) {
      this.form.get('password').setValue(null);
      this.form.updateValueAndValidity();
    }
  }

  public onLogin(): void {
    console.log('onLogin');
    this._loginService.emitLogin(this.form.value);
  }

  public onLostPassword(): void {
    this._loginService.emitLostPassword();
  }

  public chooseCountry(value): void {
    this.selectedCountry = value;
    this.form.patchValue({
      username: null,
    });
    this.loginInput.nativeElement.click();
    setTimeout(() => {
      this.loginInput.nativeElement.focus();
      this.loginInput.nativeElement.setSelectionRange(0, 0);
    }, 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
