import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { language } from '@environments/language';
declare let $: any; // not required

const moment = require('moment');

@Injectable({
  providedIn: 'root',
})
export class Util {
  currentLanguage = Language.RU;

  #language = language;

  constructor(
    private router: Router,
    protected route: ActivatedRoute,
  ) {}

  dnHref(href) {
    localStorage.setItem('url', href);
    this.router.navigate([href]);
  }

  dnHrefParam(href, param) {
    this.router.navigate([href], { queryParams: { activeTab: param, fromBoard: true } });
  }

  navigateByUrl(href) {
    this.router.navigateByUrl(href, { replaceUrl: true });
  }

  isNullOrEmpty(e: any) {
    return e === null || e === '' || e === undefined;
  }

  toSelectArray(data, idField = 'id', labelField = this.getDicNameByLanguage()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({ value: data[i][idField], label: data[i].multiLang[labelField], code: data[i].code, operationCode: data[i].operationCode });
      }
    }
    return list;
  }

  toSelectArrayInstitutionsData(data, idField = 'id', ratingField = 'rating', nameField = this.getDicNameByLanguage()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({ value: data[i][idField], name: data[i].name[nameField], rating: data[i][ratingField] });
      }
    }
    return list;
  }

  selectArray(data, idField = 'id', labelField = this.getDicNameByLanguage()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({ id: data[i][idField], label: data[i].name[labelField], code: data[i].code });
      }
    }
    return list;
  }

  addressObjectsToSelectArray(objects: any) {
    const list = [];
    const labelField = this.getDicNameByLanguage();
    if (objects) {
      const data = objects.data;
      for (const object of data) {
        const addressObject = object.addressObject.addressObject;
        list.push({ value: addressObject.id, label: addressObject.name[labelField], code: addressObject.code });
      }
    }
    return list;
  }

  streetsToSelectArray(streets: any) {
    const list = [];
    if (streets) {
      for (const street of streets.data) {
        list.push(this.streetObjectToSelectOption(street));
      }
    }
    return list;
  }

  streetObjectToSelectOption(streetObject) {
    const labelField = this.getDicNameByLanguage();
    return {
      value: streetObject.addressObject.addressObject.id,
      label: streetObject.addressObject.addressObject.name[labelField],
      addressType: streetObject.addressObject.addressType.name[labelField].toLowerCase(),
      parentByType: streetObject.parentByType?.addressObject,
      parentLabel: (streetObject.parent?.addressObject.name[labelField] ?? '') + ' ' + (streetObject.parent?.addressType.name[labelField].toLowerCase() ?? ''),
      code: streetObject.addressObject.addressObject.code,
      parent: streetObject.parent.addressObject,
    };
  }

  streetsObjectToSelectOptions(streetsObject) {
    const labelField = this.getDicNameByLanguage();
    const streets = [];
    streetsObject.forEach((streetObject) => {
      streets.push({
        value: streetObject.addressObject.addressObject.id,
        label: streetObject.addressObject.addressObject.name[labelField],
        addressType: streetObject.addressObject.addressType.name[labelField].toLowerCase(),
        parentByType: streetObject.parentByType?.addressObject,
        parentLabel: (streetObject.parent?.addressObject.name[labelField] ?? '') + ' ' + (streetObject.parent?.addressType.name[labelField].toLowerCase() ?? ''),
        code: streetObject.addressObject.addressObject.code,
      });
    });
    return streets;
  }

  toSelectArrayView(data) {
    const list = [];
    if (data?.length !== 0 && data[0] !== null) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({ value: data[i][this.getDicNameByLanguage()], label: data[i][this.getDicNameByLanguage()] });
      }
    }
    return list;
  }

  toSelectArrayDic(data, idField = 'id', labelField = this.getDicNameByLanguage()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({ value: data[i][idField], label: data[i].multiLang[labelField], code: data[i].code });
      }
    }
    return list;
  }

  toSelectArrayRoles(data, idField = 'id') {
    const list = [];
    if (data) {
      const len = data.data.length;
      if (len > 0) {
        for (let i = 0; i < len; i++) {
          const count = this.isNullOrEmpty(data.data[i].applicationCount) ? 0 : data.data[i].applicationCount;
          list.push({
            value: data.data[i][idField],
            label: data.data[i].surname?.toUpperCase().concat(' ', data.data[i].name?.toUpperCase(), ' (', count, ')'),
            applicationCount: count,
          });
        }
      }
    }

    return list;
  }

  toSelectArrayRoles2(data, idField = 'id', labelField = this.getDicName()) {
    const list = [];
    if (data) {
      const len = data.data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          value: data.data[i][idField],
          label: data.data[i][labelField],
          description: data.data[i].description,
        });
      }
    }
    return list;
  }

  toSelectArrayOrganization(data, idField = 'id', labelField = this.getDicNameByLanguage2()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          value: data[i][idField],
          label: data[i][labelField],
        });
      }
    }
    return list;
  }
  toSelectArrayAchievement(data, labelField = this.getDicNameByLanguage()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          value: data[i].id,
          label: data[i][labelField],
          photoUuid: data[i].photoUuid,
        });
      }
    }
    return list;
  }

  toSelectArrayCategories(data, labelField = this.getDicNameByLanguage()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          id: data[i].id,
          label: data[i][labelField],
          photoUuid: data[i].photoUuid,
          count: 0,
        });
      }
    }
    return list;
  }

  getArrayBySelectedId(list, itemId) {
    const selectedList = [];
    list.forEach((el) => {
      if (itemId === el.id) {
        selectedList.push(el);
      }
    });
    return selectedList;
  }

  getCommentsFromScoreList(list) {
    const hash = {};
    const selectedList = [];
    list.forEach((el: any) => {
      const comment = el.comment;
      if (!hash[comment]) {
        selectedList.push(
          (hash[comment] = {
            authorFullName: el.authorFullName,
            comment: el.comment,
          }),
        );
      }
    });
    return selectedList;
  }

  sortArrayByUniqueElements(list) {
    const hash = {};
    const selectedList = [];
    list.forEach((el) => {
      const id = el.id;
      if (hash[id]) {
        hash[id].count++;
      } else {
        selectedList.push(
          (hash[id] = {
            count: 1,
            ...el,
          }),
        );
      }
    });
    return selectedList;
  }

  toSelectArrayFlag(data, idField = 'id', labelField = this.getDicNameByLanguage()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          value: data[i][idField],
          label: data[i].multiLang[labelField],
          code: data[i].operationType.code,
          codeReason: data[i].code,
          isLeadReason: data[i].isLeadReason,
        });
      }
    }
    return list;
  }

  toSelectArrayCredit(data, idField = 'id', labelField = this.getDicNameByLanguage()) {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        let multiLang = data[i];
        if (data[i].multiLang) {
          multiLang = data[i].multiLang;
        }
        list.push({ id: data[i][idField], label: multiLang[labelField], code: data[i].code });
      }
    }
    return list;
  }

  getDicName() {
    const fieldName = 'name';
    return fieldName;
  }

  getDicNameByLanguage() {
    let fieldName;
    switch (this.#language.language) {
      case 'kz':
        fieldName = 'nameKz';
        break;
      case 'en':
        fieldName = 'nameEn';
        break;
      default:
        fieldName = 'nameRu';
        break;
    }
    return fieldName;
  }

  getDicNameByLanguage2() {
    let fieldName;
    switch (this.#language.language) {
      case 'kz':
        fieldName = 'nameKk';
        break;
      case 'en':
        fieldName = 'nameEn';
        break;
      default:
        fieldName = 'nameRu';
        break;
    }
    return fieldName;
  }

  getError() {
    let fieldName;
    switch (this.#language.language) {
      case 'kz':
        fieldName = 'kk';
        break;
      case 'en':
        fieldName = 'en';
        break;
      default:
        fieldName = 'ru';
        break;
    }
    return fieldName;
  }

  toSelectArrayResidenceComplex(data) {
    const list = [];
    if (data?.data?.data) {
      const len = data.data.size;
      const residenceComplexes = data.data.data;
      for (let i = 0; i < len; i++) {
        if (!residenceComplexes[i].houseName) {
          continue;
        }
        list.push(this.toSelectResidenceComplex(residenceComplexes[i]));
      }
    }
    return list;
  }
  toSelectResidenceComplex(residenceComplexes: any) {
    return {
      value: residenceComplexes.id,
      id: residenceComplexes.id,
      label: residenceComplexes.houseName,
      countryId: residenceComplexes.countryId,
      houseName: residenceComplexes.houseName,
      propertyDeveloperId: residenceComplexes.propertyDeveloperId,
      numberOfEntrances: residenceComplexes.numberOfEntrances,
      houseClassId: residenceComplexes.housingClass,
      housingCondition: residenceComplexes.housingCondition,
      ceilingHeight: residenceComplexes.ceilingHeight,
      concierge: residenceComplexes.concierge,
      houseNumber: residenceComplexes.houseNumber,
      houseNumberFraction: residenceComplexes.houseNumberFraction,
      materialOfConstructionId: residenceComplexes.materialOfConstructionId,
      numberOfFloors: residenceComplexes.numberOfFloors,
      parkingTypeIds: residenceComplexes.parkingTypeIds,
      playground: residenceComplexes.playground,
      typeOfElevator: residenceComplexes.typeOfElevatorIdList,
      wheelchair: residenceComplexes.wheelchair,
      yardType: residenceComplexes.yardTypeId,
      yearOfConstruction: residenceComplexes.yearOfConstruction,
      addressBuilding: residenceComplexes.addressBuilding,
    };
  }

  getValueByKey(data: any, key: any) {
    return data[key];
  }

  getObjectLength(obj: any) {
    return Object.keys(obj).length;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  getDictionaryValueById(data, id: any) {
    if (!this.isNullOrEmpty(data)) {
      for (const obj of data) {
        if (obj.value === id) {
          return obj;
        }
      }
    }
  }

  toString(data: any) {
    return data?.toString();
  }

  toBoolean(data: any) {
    return Boolean(data);
  }

  isNumeric(val: any): val is number | string {
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat (#15100)
    return !Array.isArray(val) && val - parseFloat(val) + 1 >= 0;
  }

  formatDate(date: any) {
    return formatDate(date, 'dd.MM.yyyy', 'en-US');
  }

  formatDateTime(date: any) {
    return formatDate(date, 'dd.MM.yyyy hh:mm:ss', 'en-US');
  }

  formatDateMoment(date: any, separator) {
    return moment(date).format(separator);
  }

  formatDateTimeMoment(date: any) {
    return moment(date).utc().format('DD.MM.YYYY HH:mm:ss');
  }

  formatPhoneNumber(phoneNumber: string): string {
    return '+7 (' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + ' ' + phoneNumber.substring(6, 8) + ' ' + phoneNumber.substring(8, 10);
  }

  toLocaleDateString(date: any) {
    return new Date(date).toLocaleDateString();
  }

  nvl(val: any, val2: any) {
    return this.isNullOrEmpty(val) ? val2 : val;
  }

  length(data: any) {
    return data?.length;
  }

  toSelectArrayPost(data) {
    const list = [];
    if (data) {
      const len = data.length;

      let fieldName;
      switch (this.#language.language) {
        case 'kz':
          fieldName = 'addressKaz';
          break;
        default:
          fieldName = 'addressRus';
          break;
      }

      for (let i = 0; i < len; i++) {
        list.push({
          value: data[i].postcode,
          label: data[i][fieldName],
          fullAddress: data[i],
        });
      }
    }
    return list;
  }

  hasShowAgentGroup(operation: string, roles: any) {
    if (!this.isNullOrEmpty(roles)) {
      for (const data of roles) {
        if (data.code === 'AGENT_GROUP') {
          return !data.operations.includes(operation);
        }
      }
    }
    return true;
  }

  hasShowClientGroup(operation: string, roles: any) {
    if (!this.isNullOrEmpty(roles)) {
      for (const data of roles) {
        if (data.code === 'CLIENT_GROUP') {
          return !data.operations.includes(operation);
        }
      }
    }
    return true;
  }

  hasShowApplicationGroup(operation: string, roles: any) {
    if (!this.isNullOrEmpty(roles)) {
      for (const data of roles) {
        if (data.code === 'APPLICATION_GROUP') {
          return !data.operations.includes(operation);
        }
      }
    }
    return true;
  }

  toSelectArrayGroupByPage(data, idField = 'id', surname = 'surname', name = 'name') {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          value: data[i][idField],
          label: data[i][surname] + ' ' + data[i][name],
          login: data[i].login,
        });
      }
    }
    return list;
  }

  toSelectArrayGroup(data, idField = 'id', surname = 'surname', name = 'name') {
    const list = [];
    if (data) {
      const len = data.data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          value: data.data[i][idField],
          label: data.data[i][surname] + ' ' + data.data[i][name],
          description: data.data[i].description,
          login: data.data[i].login,
        });
      }
    }
    return list;
  }

  hasShowRealPropertyGroup(operation: string, roles: any) {
    if (!this.isNullOrEmpty(roles)) {
      for (const data of roles) {
        if (data.code === 'REAL_PROPERTY_GROUP') {
          return !data.operations.includes(operation);
        }
      }
    }
    return true;
  }

  keyPress(event: KeyboardEvent) {
    return event.charCode === 8 || event.charCode === 0 || event.charCode === 13 ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  numberFormat(val: any) {
    return val ? val.toLocaleString() : '';
  }

  isEmptyObject(obj: any): boolean {
    for (const key in obj) {
      if (obj[key]) {
        return false;
      }
    }
    return true;
  }

  refresh(): void {
    window.location.reload();
  }

  deleteQueryParameterFromCurrentRoute(href) {
    const params = { ...this.route.snapshot.queryParams };
    delete params.esid;
    this.router.navigate([href], { queryParams: params });
  }

  showHideMenu(action) {
    if (action) {
      $('#sidebar-wrapper').show();
      $('#mainNavBar').show();
    } else {
      $('#sidebar-wrapper').hide();
      $('#mainNavBar').hide();
    }
  }

  notContainsGroups(operation: any, operationList: any) {
    if (!this.isNullOrEmpty(operationList)) {
      for (const data of operationList) {
        if (!this.isNullOrEmpty(data)) {
          if (operation.includes(data)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  containOperation(checkOperations: any, operationList: any) {
    for (const data of checkOperations) {
      if (operationList.includes(data)) {
        return true;
      }
    }
    return false;
  }

  youtube_parser(url) {
    if (!url) {
      return '';
    }
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return 'https://www.youtube.com/embed/' + (match && match[7].length === 11 ? match[7] : false);
  }

  pscloud_parser(url) {
    if (!url) {
      return '';
    }
    const idx = url.indexOf('.mp4');
    return url.substr(0, idx + 4);
  }

  getEnabledChangeStatus(prevStatusId: number, currentStatusId: number) {
    if (prevStatusId === undefined || currentStatusId === undefined) {
      return false;
    }

    if (prevStatusId === 9) {
      return false;
    }
    const enabledChangePrevStatus = [];
    enabledChangePrevStatus[1] = { 1: false, 3: true, 6: false, 7: false, 8: false, 10: false };
    enabledChangePrevStatus[3] = { 1: false, 3: false, 6: true, 7: false, 8: false, 10: false };
    enabledChangePrevStatus[6] = { 1: false, 3: false, 6: false, 7: true, 8: false, 10: true };
    enabledChangePrevStatus[7] = { 1: false, 3: false, 6: true, 7: false, 8: true, 10: false };
    enabledChangePrevStatus[8] = { 1: false, 3: false, 6: false, 7: false, 8: false, 10: false };
    enabledChangePrevStatus[10] = { 1: false, 3: false, 6: false, 7: true, 8: false, 10: false };

    return enabledChangePrevStatus[prevStatusId][currentStatusId];
  }

  getStatusIdsByType(type: number) {
    let ids;
    if (type === 3) {
      ids = [1, 3, 6, 7, 8];
    } else if (type === 2) {
      ids = [1, 3, 6, 7, 8];
    } else if (type === 1) {
      ids = [1, 3, 6, 7, 8, 10];
    }
    return ids;
  }

  getStatusCodesByType(type: number) {
    let code;
    if (type === 3) {
      code = ['002001', '002003', '002006', '002010', '002007', '002008'];
    } else if (type === 2) {
      code = ['002001', '002003', '002006', '002007', '002008'];
    } else if (type === 1) {
      code = ['002001', '002003', '002006', '002010', '002007', '002008'];
    }
    return code;
  }

  toSelectAgent(data, idField = 'id', surname = 'surname', name = 'name') {
    const list = [];
    if (data) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          value: data[i][idField],
          label: data[i][surname] + ' ' + data[i][name],
          description: data[i].description,
          login: data[i].login,
          isActive: data[i].isActive,
          userId: data[i].userId,
        });
      }
    }
    return list;
  }

  toSelectCategoryArrayOfIds(data, field) {
    const list = [];
    for (const i of data) {
      list.push(i[field]);
    }
    return list;
  }

  getPeriodTitle(firstStr: any, secondStr: any, symbol: string): string {
    let str = '';
    if (!this.isNullOrEmpty(firstStr) && !this.isNullOrEmpty(secondStr)) {
      str += firstStr + symbol + secondStr;
    } else if (!this.isNullOrEmpty(firstStr)) {
      str += 'от ' + firstStr;
    } else if (!this.isNullOrEmpty(secondStr)) {
      str += 'до ' + secondStr;
    }
    return str;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  isNotEmptyArray(obj: any, key: string) {
    return obj.hasOwnProperty(key) && obj[key] !== null && obj[key].length;
  }

  toSelectAgentByGroup(data, idField = 'id', surname = 'surname', name = 'name') {
    const list = [];
    if (data) {
      const len = data.data.length;
      for (let i = 0; i < len; i++) {
        list.push({
          value: data.data[i][idField],
          label: data.data[i][surname] + ' ' + data.data[i][name],
          description: data.data[i].description,
          login: data.data[i].login,
          userId: data.data[i].userId,
          choose: 'Выбрать все',
        });
      }
    }
    return list;
  }

  getCurrentLanguageAsKey(): string {
    return LanguageKey[this.currentLanguage.toLocaleUpperCase()];
  }

  public isContainCyrillicCharacters(text: string): boolean {
    const cyrillicPattern = /[а-яА-ЯЁё]/;
    return cyrillicPattern.test(text);
  }

  public checkPermissionToEdit(operationList: string[]): boolean {
    if (operationList.length !== 0) {
      for (const operation of operationList) {
        if (operation.includes('UPDATE_')) {
          return true;
        }
      }
    }
    return false;
  }
}

enum Language {
  EN = 'en',
  RU = 'ru',
  KZ = 'kz',
}

enum LanguageKey {
  EN = 'nameEn',
  RU = 'nameRu',
  KZ = 'nameKz',
}
