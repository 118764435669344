import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AddressTypeEnum, StreetFilterDto } from '@modules/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GisService {
  constructor(private http: HttpClient) {}
  getCurrentPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          alert('Пожалуйста дайте доступ к геолокации');
          reject(err);
        },
      );
    });
  }

  public getAddressObjects(type: AddressTypeEnum[], parentCode: string | string[]): Observable<any> {
    let params = '';
    let index = 0;
    for (const item of type) {
      if (index !== 0) {
        params += '&';
      }
      params += 'typeId=' + item;
      index++;
    }
    if (parentCode) {
      params += '&parentCode=' + parentCode;
    }
    return this.http.get(`${environment.apiGisManagerUrl}/api/address/getObjects?` + params);
  }

  public async getAddressObjectsAsync(type: AddressTypeEnum[], parentCode: string) {
    let index = 0;
    let params = '';
    for (const item of type) {
      if (index !== 0) {
        params += '&';
      }
      params += 'typeId=' + item;
      index++;
    }
    if (parentCode) {
      params += '&parentCode=' + parentCode;
    }
    return await this.http.get<any>(`${environment.apiGisManagerUrl}/api/address/getObjects?` + params, {}).toPromise();
  }

  public async getStreet(streetFilterDto: StreetFilterDto) {
    return this.http.post<any>(`${environment.apiGisManagerUrl}/api/address/getStreetsByParent`, { ...streetFilterDto }).toPromise();
  }

  public getStreetByParent(streetFilterDto: StreetFilterDto) {
    return this.http.post<any>(`${environment.apiGisManagerUrl}/api/address/getStreetsByParent`, { ...streetFilterDto });
  }

  public async getStreetById(streetId: number, typeId: number) {
    return this.http.get<any>(`${environment.apiGisManagerUrl}/api/address/getStreet/` + streetId + '/' + typeId, {}).toPromise();
  }

  public async getStreetsById(streetIds: number[], typeId: number) {
    let params = new HttpParams().set('typeId', typeId);

    streetIds.forEach((streetId) => {
      params = params.append('ids', streetId);
    });
    return this.http.post<any>(`${environment.apiGisManagerUrl}/api/address/getStreetByIds`, params).toPromise();
  }

  public async getHouseNumbers(streetIds: number[] | number) {
    const params = this.createParamsForBuildings(streetIds);
    return await this.http.get<any>(`${environment.apiGisManagerUrl}/api/address/getBuildingsByStreet/`, { params }).toPromise();
  }

  public getBuildingsByStreet(streetIds: number[] | number) {
    const params = this.createParamsForBuildings(streetIds);
    return this.http.get<any>(`${environment.apiGisManagerUrl}/api/address/getBuildingsByStreet/`, { params });
  }

  private createParamsForBuildings(streetIds: number[] | number) {
    let params = new HttpParams();
    if (Array.isArray(streetIds) && streetIds.length) {
      streetIds.forEach((id: number) => {
        params = params.append('streetIds', id);
      });
    } else if (!Array.isArray(streetIds) && streetIds) {
      params = params.append('streetIds', streetIds);
    }
    return params;
  }
  public getStreetList(search): Observable<any> {
    return this.http.post<any>(`${environment.apiGisManagerUrl}/api/street/list/`, { ...search });
  }

  public deleteObject(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiGisManagerUrl}/api/address/` + id);
  }

  public deleteAddress(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiGisManagerUrl}/api/street/` + id);
  }

  public deleteBuilding(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiGisManagerUrl}/api/building/` + id);
  }

  public saveObject(bodyDto): Observable<any> {
    return this.http.post<any>(`${environment.apiGisManagerUrl}/api/address/addObject`, { ...bodyDto });
  }

  public saveStreet(bodyDto): Observable<any> {
    return this.http.post<any>(`${environment.apiGisManagerUrl}/api/street/addStreet`, { ...bodyDto });
  }

  public saveBuilding(bodyDto): Observable<any> {
    return this.http.post<any>(`${environment.apiGisManagerUrl}/api/building`, { ...bodyDto });
  }

  public getObjectById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiGisManagerUrl}/api/address/` + id);
  }

  public getStreetOnlyById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiGisManagerUrl}/api/street/` + id);
  }

  public getBuildingOnlyById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiGisManagerUrl}/api/building/` + id);
  }

  public updateBuilding(bodyDto): Observable<any> {
    return this.http.put<any>(`${environment.apiGisManagerUrl}/api/building/updateObject`, { ...bodyDto });
  }

  public updateStreet(bodyDto): Observable<any> {
    return this.http.put<any>(`${environment.apiGisManagerUrl}/api/street/updateStreet`, { ...bodyDto });
  }

  public updateObject(bodyDto): Observable<any> {
    return this.http.put<any>(`${environment.apiGisManagerUrl}/api/address/updateObject`, { ...bodyDto });
  }
}
