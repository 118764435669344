<form class="login-form" [formGroup]="form" (ngSubmit)="onLogin()" [class.is-mobile]="isMobile">
  <div class="form-group">
    <label>Логин</label>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
      <ng-select
        [clearable]="false"
        placeholder="Страна"
        [items]="countries"
        bindLabel="name"
        [clearOnBackspace]="false"
        [searchable]="false"
        (change)="chooseCountry($event)"
        [class.selected]="selectedCountry">
        <ng-template ng-label-tmp let-item="item">
          <span pInputText class="label-country">
            <img height="20" width="20" [src]="'assets/images/flags/' + item.flag" alt="{{item.flag}} + flags"/>
            {{item.code}}
          </span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <img height="20" width="20" [src]="'assets/images/flags/' + item.flag" alt="{{item.flag}} + flags"/>
          <span class="country-flag">
            {{ item.name }} ({{ item.code }})
          </span>
        </ng-template>
      </ng-select>
      <input
        #loginInput
        [readOnly]="!selectedCountry"
        type="text"
        pInputText
        placeholder="Телефон"
        formControlName="username"
        autocomplete="off"
        autofocus
        [mask]="selectedCountry?.mask"
        [showMaskTyped]="true"
        (keyup.enter)="onLogin()"/>
    </div>
  </div>

  <div class="form-group mt-2">
    <label>Пароль</label>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-lock"></i>
      </span>
      <input
        #x
        type="password"
        pInputText
        placeholder="Пароль"
        formControlName="password"
        aria-label="password"
        autocomplete="off"
        (keyup.enter)="onLogin()"/>
      <button type="button" class="p-inputgroup-addon" (click)="x.type = x.type === 'password' ? 'text' : 'password'">
        <i class="pi pi-eye" *ngIf="x.type === 'text'"></i>
        <i class="pi pi-eye-slash" *ngIf="x.type === 'password'"></i>
      </button>
    </div>
  </div>

  <div class="login-form__message mt-2">
    <div *ngIf="errorMessage$ | async as errorMessage" class="message">
      <span class="error">{{ errorMessage }}</span>
    </div>
  </div>
  <div class="login-form__actions flex flex-column md:flex-row" [ngClass]="{
    'align-items-center justify-content-between mt-3': !isMobile,
    'justify-content-end mt-1 align-items-end': isMobile,
  }">
    <button type="button" class="login-form__action login-form__action--txt btn-link" (click)="onLostPassword()">Забыли пароль?</button>

    <button pButton class="login-form__action login-form__action--filled" [ngClass]="{
        'mt-3 md:mt-0 btn': !isMobile,
        'mt-1': isMobile,
      }" type="submit" label="Войти" [loading]="isLoading$ | async" [disabled]="this.form.invalid">
    </button>
  </div>
</form>