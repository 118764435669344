import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  @Input() public title: string;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  actionOnClose() {
    this.closeModal.emit();
  }
}
