export enum AddressTypeEnum {
  MainCity = 1,
  District = 2,
  Address = 3,
  Building = 4,
  Region = 178,
  Locality = 50,
  CityRegional = 49,
  City = 57,
  Country = 179,
}
