<ng-container [ngSwitch]="readonly">
  <ng-container *ngSwitchCase="false">
    <p-inputNumber
      [maxFractionDigits]="2"
      [minlength]="minlength"
      [maxlength]="maxlength"
      [required]="required"
      locale="ru-RU"
      type="number"
      [readonly]="readonly"
      [formControl]="formControl"
      [placeholder]="placeholder"
      [ngClass]="{'red-input': formControl?.errors?.min, 'input-group-right': inputGroupRight, 'input-group-left': inputGroupLeft}"
      (ngModelChange)="onNgModelChange($event)"
      (change)="onChange()">
    </p-inputNumber>
  </ng-container>
  <ng-container *ngSwitchCase="true">
    <input
      step="0.01"
      class="form-control"
      type="number"
      [readonly]="readonly"
      [ngClass]="{ 'input-group-right': inputGroupRight, 'input-group-left': inputGroupLeft}"
      [value]="value"/>
  </ng-container>
</ng-container>
