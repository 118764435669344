import { NotificationType } from './index';

export class Notification {
  notificationId: number;
  notesId: number;
  realPropertyId: number;
  applicationId: number;
  message: string;
  notificationType: NotificationType;
  messageDate: Date;
  answer: Notification;
  questionId: number;
}
