import { Notification } from './notification';

export class NotificationAddresses {
  notificationAddressId: number;
  notification: Notification;
  login: string;
  isOpened: boolean;
  isReadBody = false;
  isWriteAnswer = false;
}
