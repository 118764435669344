import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-view-video',
  templateUrl: './modal-view-video.component.html',
  styleUrls: ['./modal-view-video.component.scss'],
})
export class ModalViewVideoComponent {
  @Input()
  public applicationId;

  @Input()
  public videoUrl;

  @Input()
  public isShowVideoScore: boolean;

  constructor(private modalRef: NgbActiveModal) {}

  onClose() {
    this.modalRef.close();
  }
}
