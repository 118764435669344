import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import localeKz from '@angular/common/locales/ru-KZ';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { JwtInterceptor } from '@helpers/jwt.interceptor';
import { VideoPlayerModule } from '@modules/video-player/video-player.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ruLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { ButtonModule } from 'primeng/button';
import { CalendarModule as CalendarModulePrimeNg } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { StepsModule } from 'primeng/steps';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { ApprovalComponent } from './components/approval/approval.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalViewVideoComponent } from './components/modal-view-video/modal-view-video.component';
import { NameComponent } from './components/name/name.component';
import { InputMaskComponent } from './components/phone-input/phone-input.component';
import { SingleFloatingPointInputComponent } from './components/single-floating-point-input/single-floating-point-input.component';
import { SingleFloatingPointValueViewComponent } from './components/single-floating-point-value-view/single-floating-point-value-view.component';
import { ValidationMessagesDirective } from './directives/validation-messages.directive';
import { AgentSelectComponent } from './modules/agent-select/agent-select.component';
import { GroupsSelectComponent } from './modules/groups-select/groups-select.component';
import { SpinnerModule } from './modules/spinner/spinner.module';
import { services } from './services';
import { ShowMaskPhonePipe } from './pipes/show-number-mask/show-number-mask.component';
const mapConfig: YaConfig = {
  apikey: 'f6dfacc5-49bf-4701-9f5b-1b51c5d76c60',
  lang: 'ru_RU',
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'indigo',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 80,
  bgsType: SPINNER.threeStrings, // background spinner type
  fgsType: SPINNER.threeStrings, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};

registerLocaleData(localeRu, localeKz);
defineLocale('ru', ruLocale);

@NgModule({
  declarations: [
    NameComponent,
    InputMaskComponent,
    ValidationMessagesDirective,
    ModalViewVideoComponent,
    ModalHeaderComponent,
    SingleFloatingPointInputComponent,
    SingleFloatingPointValueViewComponent,
    FormFieldComponent,
    ApprovalComponent,
    AgentSelectComponent,
    GroupsSelectComponent,
    ShowMaskPhonePipe,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    HttpClientModule,
    TreeModule,
    TreeTableModule,
    TooltipModule,
    StepsModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    FormsModule,
    EditorModule,
    ReactiveFormsModule,
    DragDropModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-right' }),
    AngularYandexMapsModule.forRoot(mapConfig),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgbModule,
    CollapseModule,
    RadioButtonModule,
    SelectButtonModule,
    ButtonModule,
    ToggleButtonModule,
    ProgressBarModule,
    CalendarModulePrimeNg,
    CheckboxModule,
    InfiniteScrollModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    TabViewModule,
    RippleModule,
    InputSwitchModule,
    MenuModule,
    CardModule,
    SlideMenuModule,
    VideoPlayerModule,
    SpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    TranslateModule,
    HttpClientModule,
    DragDropModule,
    CalendarModule,
    AngularYandexMapsModule,
    NgxUiLoaderModule,
    ToastrModule,
    ModalModule,
    BsDatepickerModule,
    PaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    CollapseModule,
    RadioButtonModule,
    SelectButtonModule,
    ButtonModule,
    ToggleButtonModule,
    ProgressBarModule,
    CalendarModulePrimeNg,
    CheckboxModule,
    InfiniteScrollModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    TabViewModule,
    RippleModule,
    MenuModule,
    CardModule,
    TreeModule,
    TreeTableModule,
    TooltipModule,
    StepsModule,
    SlideMenuModule,
    InputSwitchModule,
    VideoPlayerModule,
    NameComponent,
    InputMaskComponent,
    ValidationMessagesDirective,
    ModalViewVideoComponent,
    ModalHeaderComponent,
    EditorModule,
    SingleFloatingPointInputComponent,
    SingleFloatingPointValueViewComponent,
    FormFieldComponent,
    ApprovalComponent,
    SpinnerModule,
    AgentSelectComponent,
    GroupsSelectComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    ShowMaskPhonePipe,
  ],
  providers: [...services, provideNgxMask(), { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
})
export class SharedModule {}
