import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as models from '../models';

export type uploadMultiPartFileInitAPIResp = {
  code: number;
  data: string;
  messageText: string;
  success: boolean;
};

export type uploadFileChunkAPIResp = {
  code: number;
  data: string;
  messageText: string;
  success: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient) {}

  public uploadData(selectedFile: File): Observable<any> {
    const uploadData = new FormData();
    uploadData.append('file', selectedFile, selectedFile.name);

    return this.http
      .post<any>(`${environment.apiFileManagerUrl}/api/upload`, uploadData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event: any) => this.getEventMessage(event)),
        catchError(this.handleError),
      );
  }

  public getHeadersById(guid): Observable<any> {
    return this.http.get<any>(`${environment.apiFileManagerUrl}/api/download/${guid}`, {
      observe: 'response',
    });
  }

  public getPhotoById(guid): Observable<any> {
    return this.http.get<any>(`${environment.apiFileManagerUrl}/api/download/${guid}`);
  }

  public getFileInfoUsingGET(guid): Observable<any> {
    return this.http.get<any>(`${environment.apiFileManagerUrl}/api/info/${guid}`);
  }

  public getPhotoByIdPreview(guid: string): Observable<string> {
    return this.http.get<string>(`${environment.apiFileManagerUrl}/api/download/${guid}/preview`);
  }

  public removePhotoById(guid): Observable<any> {
    return this.http.delete<any>(`${environment.apiFileManagerUrl}/api/delete/` + guid, {});
  }

  public getResumePhoto(guid: models.GetFileParams): Observable<any> {
    return this.http.get(`${environment.apiFileManagerUrl}/api/download/${guid}`, { responseType: 'blob' });
  }

  public getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
      case HttpEventType.Response:
        return this.apiResponse(event);
    }
  }

  public fileUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    return { status: 'progress', message: percentDone };
  }

  public apiResponse(event) {
    return event.body;
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(() => 'Something bad happened. Please try again later.');
  }

  public generatorPreviewUrl(uuid: string): string {
    return `${environment.apiFileManagerUrl}/open-api/download/${uuid}/preview`;
  }

  public generatorFullUrl(uuid: string): string {
    return `${environment.apiFileManagerUrl}/open-api/download/${uuid}`;
  }

  uploadVideo(file: File): Observable<any> {
    const uploadData = new FormData();
    uploadData.append('file', file);
    return this.http
      .post<any>(`${environment.apiFileManagerUrl}/open-api/video/upload`, uploadData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event: any) => this.getEventMessage(event)),
        catchError(this.handleError),
      );
  }

  getVideo(file: string): Observable<any> {
    return this.http.get(`${environment.apiFileManagerUrl}/open-api/video/get-file/${file}`);
  }

  deleteVideo(file: string): Observable<any> {
    return this.http.post<any>(`${environment.apiFileManagerUrl}/open-api/video/delete-file/${file}`, {});
  }

  public completeDirectUpload(fileName: string, fileSize: number): Observable<any> {
    return this.http.post(`${environment.apiFileManagerUrl}/api/video/complete-direct-upload?fileName=${fileName}&fileSize=${fileSize}`, null);
  }

  public getPreSignedUrl(fileName: string): Observable<any> {
    return this.http.post(`${environment.apiFileManagerUrl}/api/video/get-presigned-url?fileName=${fileName}`, null, { responseType: 'text' });
  }
}
