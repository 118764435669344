import { Injectable } from '@angular/core';
import { LoginModel } from '@modules/login/utils/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private lostPasswordSubject$ = new Subject<boolean>();
  public lostPassword$ = this.lostPasswordSubject$.asObservable();

  private loginSubject$ = new Subject<LoginModel>();
  public login$ = this.loginSubject$.asObservable();

  private errorMessageSubject$ = new Subject<string>();
  public errorMessage$: Observable<string> = this.errorMessageSubject$.asObservable();

  private isLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject$.asObservable();

  emitLostPassword() {
    this.lostPasswordSubject$.next(true);
  }

  emitLogin(dataUser: LoginModel) {
    this.loginSubject$.next(dataUser);
  }

  emitErrorMessage(message: string) {
    this.errorMessageSubject$.next(message);
  }

  emitLoading(value: boolean) {
    this.isLoadingSubject$.next(value);
  }
}
