import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  updateProfile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private http: HttpClient) {}

  /**
   * Просмотр реестра клиентов
   */
  getProfile(): Observable<any> {
    return this.http.get<any>(`${environment.apiUserManagerUrl}/api/profile`);
  }

  updateProfile(obj: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUserManagerUrl}/api/profile`, obj).pipe(
      tap(() => {
        this.updateProfile$.next(true);
      }),
    );
  }
}
