import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { JwtInterceptor } from '@helpers/jwt.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthGuard } from '@helpers/auth.guard';
import { AuthenticationService, DicService, UserService } from '@modules/shared/services';
import { SharedModule } from '@modules/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { of, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationsUtil } from './notifications.util';
import { SystemDictionariesResolver } from './resolvers/system-dictionaries.resolver';

import * as consts from '../const';
export function HttpLoaderFactory(httpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

export function redirectToRealPropertyDetails(router: Router) {
  if (window.location.href.includes('claim:claim')) {
    let url = window.location.href.toString();
    url = url.split('#').pop();
    const claimId = url.match(/\d+/);
    return () => router.navigate(['claims', +claimId[0]]);
  } else {
    return () => null;
  }
}

export function authenticateUser(authService: AuthenticationService, userService: UserService, router: Router) {
  if (window.location.href.includes('lead-view')) {
    let url = window.location.href.toString();
    url = url.split('#').pop();
    authService.currentRouteState = url;
  }
  if (!localStorage.getItem('token')) {
    router.navigateByUrl('login');
    return () => null;
  }
  return () =>
    userService
      .findUserByLogin()
      .pipe(
        tap((user) => authService.setCurrentUser(user)),
        catchError((e) => {
          console.log(e);
          if (e?.status === 401) {
            router.navigateByUrl('login');
          }
          return of(null);
        }),
      )
      .subscribe();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ru',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: authenticateUser,
      deps: [AuthenticationService, UserService, Router, DicService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: redirectToRealPropertyDetails,
      deps: [Router],
      multi: true,
    },
    {
      provide: consts.VIEW_MANAGER_URI,
      useValue: environment.apiViewManagerUrl,
    },
    {
      provide: consts.DATA_MANAGER_URI,
      useValue: environment.apiDataManagerUrl,
    },
    {
      provide: consts.USER_MANAGER_URI,
      useValue: environment.apiUserManagerUrl,
    },
    {
      provide: consts.FILE_MANAGER_URI_TOKEN,
      useValue: environment.apiFileManagerUrl,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
    AuthenticationService,
    DicService,
    SystemDictionariesResolver,
    NotificationsUtil,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
