import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  // TODO: вынести в свой модуль
  public findByLoginAndAppId(login: string, appId: number): Observable<any> {
    const url = appId
      ? `${environment.apiUserManagerUrl}/api/profile-client/findByLoginAndAppId?login=${login}&appId=${appId}`
      : `${environment.apiUserManagerUrl}/api/profile-client/findByLoginAndAppId?login=${login}`;
    return this.http.get<any>(url);
  }

  // TODO: вынести в свой модуль
  public updateClientById(appId: string, form: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUserManagerUrl}/api/profile-client/crm?appId=${appId}`, form);
  }
}
