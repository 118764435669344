import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  @Input()
  public readonly labelForId: string = null;

  @Input()
  public readonly label: string = null;

  @Input() public readonly labelAdditionalText: string = null;

  @Input()
  public readonly className: string = 'form-field';

  @Input()
  public readonly required: boolean = false;
}
