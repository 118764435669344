export class User {
  id: number;
  personId: number;
  username: string;
  password: string;
  access_token: string;
  refresh_token: string;
  login: string;
  name: string;
  surname: string;
  group: string;
  roles: string[];
  photoUuid: string;
}

import * as um from 'src/generated/api/um.api';
import * as vm from 'src/generated/api/vm.api';

type GetUserListByFilter = um.paths['/api/users/filter']['post'];
export type GetUserListByFilterParams = GetUserListByFilter['parameters']['body']['dto'];
export type GetUserListByFilterResp = GetUserListByFilter['responses']['200']['schema'];

type GetUserInfo = um.paths['/api/users/info']['get'];
export type GetUserInfoResp = GetUserInfo['responses']['200']['schema'];

type UserList = um.paths['/api/users/list']['post'];
export type UserListParams = UserList['parameters']['body']['pageableDto'];
export type UserListResp = UserList['responses']['200']['schema'];

type GetAgentListToAssign = vm.paths['/api/agents/getAgentsToAssign']['get'];
export type GetAgentListToAssignResp = GetAgentListToAssign['responses']['200']['schema'];
