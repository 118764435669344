import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appValidationMessages]',
})
export class ValidationMessagesDirective implements OnInit {
  @Input() errorMessages: any;

  @HostListener('blur')
  handleBlurEvent() {
    this.checkValidationControl();
  }

  errorSpanId = '';
  statusChangeSubscription: Subscription;

  constructor(
    private _elRef: ElementRef,
    private _control: NgControl,
  ) {}

  ngOnInit(): void {
    this.errorSpanId = this._control.name + '-message--error';
    this.statusChangeSubscription = this._control.statusChanges.subscribe((status) => {
      if (status === 'INVALID' && this._control.touched) {
        this.showError();
      } else {
        this.removeError();
      }
    });
  }

  checkValidationControl() {
    if (this._control.errors && (this._control.touched || this._control.dirty)) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  showError() {
    this.removeError();
    this._elRef.nativeElement.classList.add('border-danger');
    this._elRef.nativeElement.parentElement.classList.add('position-relative');
    this._elRef.nativeElement.parentElement.appendChild(this.createSpanError());
  }

  createSpanError() {
    const valErrors: ValidationErrors = this._control.errors;
    const firstKey = Object.keys(valErrors)[0];
    const errorMsg = this.errorMessages[this._control.name][firstKey];
    const spanErr = document.createElement('div');
    spanErr.classList.add('message-error', 'text-danger');
    spanErr.id = this.errorSpanId;
    spanErr.innerText = errorMsg || '';
    return spanErr;
  }

  removeError() {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) errorElement.remove();
    this._elRef.nativeElement.classList.remove('border-danger');
  }
}
