import { BaseEntity } from '../base.entity';
import { EmployeeData } from './employee-data';

/**
 * "Модель сущности Личный кабинет"
 */

export class ProfileDto implements BaseEntity {
  public id?: number;
  public address?: string;
  public agentContractAmount?: number;
  public depositAmount?: number;
  public email?: string;
  public phone?: string;
  public photoUuid?: string;
  public rating?: number;
  public saleAmount?: number;
  public transport?: string;
  public employeeData?: EmployeeData;
  public aboutMe?: string;
  public referralCode?: string;
  public cityId: number;
  public surname?: string;
  public name?: string;
  public workExperience: string;
  public analyticsRating: number;
}
