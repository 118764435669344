import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObjectService {
  constructor(private http: HttpClient) {}

  getRealPropertyList(body: any): Observable<any> {
    return this.http.post<any>(`${environment.apiViewManagerUrl}/api/realProperty/getRealPropertyList`, body);
  }

  getRealPropertyWithAppList(search: any): Observable<any> {
    return this.http.post<any>(`${environment.apiViewManagerUrl}/api/register/getRealPropertyWithAppList`, search);
  }

  getSameApplicationList(params): Observable<any> {
    return this.http.post<any>(`${environment.apiViewManagerUrl}/api/sell-application/getSameApplicationList`, params);
  }
}
