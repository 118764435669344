<ng-select
  [placeholder]="placeholder"
  loadingText="Загрузка..."
  bindLabel="name"
  [bindValue]="bindValue"
  [loading]="loading"
  [readonly]="isDisabled"
  (open)="onOpen()"
  (search)="onSearch($event)"
  [items]="groupsList"
  [searchFn]="returnTrue"
  (change)="onChange($event)"
  [(ngModel)]="selectedValue"
>
  <ng-template ng-label-tmp let-item="item">
    <span>
      {{ item.name || groupName }}
    </span>
  </ng-template>
</ng-select>