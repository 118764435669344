import { Injectable } from '@angular/core';

import { DicService, DictionaryList, SystemDictionaryList } from '@modules/shared/services';
import { catchError, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemDictionariesResolver {
  constructor(private dictionaryService: DicService) {}

  resolve(): Observable<void> {
    if (this.dictionaryService.dictionaryList.length === 0) {
      this.dictionaryService
        .getDictionaryValueList(DictionaryList.OperationType)
        .pipe(tap((list) => (this.dictionaryService.operationTypeList = list)))
        .subscribe();
      this.dictionaryService
        .getDictionaryValueList(DictionaryList.ObjectType)
        .pipe(tap((list) => (this.dictionaryService.objectTypeList = list)))
        .subscribe();
      this.dictionaryService
        .getDictionariesWithValues([...Object.values(SystemDictionaryList).filter((k) => typeof k === 'string')])
        .pipe(
          tap((list) => (this.dictionaryService.dictionaryList = list)),
          tap((list) => this.dictionaryService.fromDictionaryListArrayToHash(list)),
          catchError((err) => {
            console.log(err);
            return of(null);
          }),
        )
        .subscribe();
      return;
    }
  }
}
