import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as sharedModels from '@modules/shared/models';
import { GisService } from '@modules/shared/services/gis.service';
import { Util } from '@modules/shared/services/util';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Dic } from '../models/dic';

export type DictionaryWithValues = { dictionaryName: string; values: Dic[] };

@Injectable({
  providedIn: 'root',
})
export class DicService {
  public dictionaryList: DictionaryWithValues[] = [];

  public dictionaryListAsHashAsSelectOptionList: { [dictionaryName: string]: { value: number; label: string }[] } = {};

  public operationTypeList: Dic[] = [];

  public objectTypeList: Dic[] = [];

  constructor(
    private _http: HttpClient,
    private _util: Util,
    private _gisService: GisService,
  ) {}

  public fromDictionaryListArrayToHash(list: DictionaryWithValues[], currentLanguageAsKey = this._util.getCurrentLanguageAsKey()) {
    for (const dictionary of list) {
      this.dictionaryListAsHashAsSelectOptionList[dictionary.dictionaryName] = dictionary.values.map((value) => ({ value: value.id, label: value.multiLang[currentLanguageAsKey] }));
    }
  }

  public getDicsAllPageable(config): Observable<any> {
    if (config.dictionaryName === 'School') {
      return this._http.post<any>(`${environment.apiDataManagerUrl}/api/school/list/pageable`, config.pageableDto);
    } else if (config.dictionaryName === 'Hospital') {
      return this._http.post<any>(`${environment.apiDataManagerUrl}/api/hospital/list/pageable`, config.pageableDto);
    } else if (config.dictionaryName === 'Kindergarten') {
      return this._http.post<any>(`${environment.apiDataManagerUrl}/api/kindergarten/list/pageable`, config.pageableDto);
    } else if (config.dictionaryName === 'ShoppingCenter') {
      return this._http.post<any>(`${environment.apiDataManagerUrl}/api/shop/list/pageable`, config.pageableDto);
    } else {
      return this._http.post<any>(`${environment.apiDataManagerUrl}/api/dictionaries/list/pageable`, config);
    }
  }

  public saveDictionary(data: Dic): Observable<any> {
    return this._http.post(`${environment.apiDataManagerUrl}/api/dictionaries`, data);
  }

  public deleteDictionaryById(dicName: string, id: number): Observable<any> {
    return this._http.delete(`${environment.apiDataManagerUrl}/api/dictionaries/${dicName}/${id}`);
  }

  public getDictionary(dicName: string): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/dictionaries/${dicName}/list`);
  }

  public updateDictionary(data: Dic, id: number): Observable<any> {
    return this._http.put(`${environment.apiDataManagerUrl}/api/dictionaries/${id}`, data);
  }

  public getDics(dictionaryName: string): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/dictionaries/${dictionaryName}/list`);
  }

  getDocumentTypes(): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/docs/getTypes`);
  }

  getStatuses(): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/docs/getStatuses`);
  }

  public getAllHospitalList(): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/hospital/list`);
  }

  public getAllKinderGardenList(): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/kindergarten/list`);
  }

  public getAllSchoolList(): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/school/list`);
  }

  public getAllShoppingCenterList(): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/shop/list`);
  }

  public saveSchool(data: Dic): Observable<any> {
    return this._http.post(`${environment.apiDataManagerUrl}/api/school`, data);
  }

  public updateSchool(data: Dic, id: number): Observable<any> {
    return this._http.put(`${environment.apiDataManagerUrl}/api/school/${id}`, data);
  }

  public deleteSchoolById(id: number): Observable<any> {
    return this._http.delete(`${environment.apiDataManagerUrl}/api/school/${id}`);
  }

  public saveHospital(data: Dic): Observable<any> {
    return this._http.post(`${environment.apiDataManagerUrl}/api/hospital`, data);
  }

  public updateHospital(data: Dic, id: number): Observable<any> {
    return this._http.put(`${environment.apiDataManagerUrl}/api/hospital/${id}`, data);
  }

  public deleteHospitalById(id: number): Observable<any> {
    return this._http.delete(`${environment.apiDataManagerUrl}/api/hospital/${id}`);
  }

  public saveKindergarten(data: Dic): Observable<any> {
    return this._http.post(`${environment.apiDataManagerUrl}/api/kindergarten`, data);
  }

  public updateKindergarten(data: Dic, id: number): Observable<any> {
    return this._http.put(`${environment.apiDataManagerUrl}/api/kindergarten/${id}`, data);
  }

  public deleteKindergartenById(id: number): Observable<any> {
    return this._http.delete(`${environment.apiDataManagerUrl}/api/kindergarten/${id}`);
  }

  public saveShoppingCenter(data: Dic): Observable<any> {
    return this._http.post(`${environment.apiDataManagerUrl}/api/shop`, data);
  }

  public updateShoppingCenter(data: Dic, id: number): Observable<any> {
    return this._http.put(`${environment.apiDataManagerUrl}/api/shop/${id}`, data);
  }

  public deleteShoppingCenterById(id: number): Observable<any> {
    return this._http.delete(`${environment.apiDataManagerUrl}/api/shop/${id}`);
  }

  public getLeadApplicationSources(): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/dictionaries/getLeadApplicationSources`);
  }

  public getLeadApplicationStatuses(): Observable<any> {
    return this._http.get(`${environment.apiDataManagerUrl}/api/dictionaries/getLeadApplicationStatuses`);
  }

  public getDictionariesWithValues(dictionaryNameList: sharedModels.GetDictionariesWithValuesParams) {
    const params = new HttpParams().appendAll({ dictionaryName: dictionaryNameList });
    return this._http.get<DictionaryWithValues[]>(`${environment.apiDataManagerUrl}/api/dictionaries/getByDictionaries`, { params }).pipe(
      catchError((err) => {
        console.log(err);
        return of(null);
      }),
    );
  }

  public getSystemDictionaryValuesAsSelectOptionList(dictionaryName: SystemDictionaryList, currentLanguageAsKey?: string) {
    return currentLanguageAsKey
      ? this.dictionaryList.find((dictionary) => dictionary.dictionaryName === dictionaryName).values.map((value) => ({ value: value.id, label: value.multiLang[currentLanguageAsKey] }))
      : this.dictionaryListAsHashAsSelectOptionList[dictionaryName];
  }

  getDictionaryValueList(dictionary: DictionaryList, value = 'value', label = 'label', id = 'id', name = 'multiLang', multiLang = true): Observable<Dic[]> {
    const currentLanguageAsKey = this._util.getCurrentLanguageAsKey();

    switch (dictionary) {
      case DictionaryList.ObjectType:
      case DictionaryList.OperationType:
        return this._http.get<any>(`${environment.apiDataManagerUrl}/api/dictionaries/${dictionary}/list`).pipe(
          map((res) => res.map((item) => ({ [value]: item[id], [label]: multiLang ? item[name][currentLanguageAsKey] : item[name] }))),
          catchError((err) => {
            console.log(err);
            return of(null);
          }),
        );
      case DictionaryList.City:
        return this._gisService
          .getAddressObjects([sharedModels.AddressTypeEnum.MainCity, sharedModels.AddressTypeEnum.Region], null)
          .pipe(map((res) => res.data.map((item) => ({ [value]: item.addressObject.addressObject.id, [label]: item.addressObject.addressObject.name[currentLanguageAsKey] }))));
      case DictionaryList.LeadApplicationStatus:
        return this.getLeadApplicationStatuses().pipe(map((res) => res.map((item) => ({ [value]: item[id], [label]: multiLang ? item[name][currentLanguageAsKey] : item[name] }))));
      case DictionaryList.LeadApplicationSource:
        return this.getLeadApplicationSources().pipe(map((res) => res.map((item) => ({ [value]: item[id], [label]: multiLang ? item[name][currentLanguageAsKey] : item[name] }))));
    }
  }
}

export enum DictionaryList {
  OperationType = 'OperationType',
  ObjectType = 'ObjectType',
  City = 'City',
  LeadApplicationStatus = 'LeadApplicationStatus',
  LeadApplicationSource = 'LeadApplicationSource',
  AgentList = 'AgentList',
  ApplicationStatus = 'ApplicationStatus',
}

export enum SystemDictionaryList {
  OperationType = 'OperationType',
  ObjectType = 'ObjectType',
  ApplicationStatus = 'ApplicationStatus',
}
