<ng-select
  [loading]="loading"
  loadingText="Загрузка..."
  [items]="userList"
  [bindValue]="bindValue"
  [bindLabel]="bindLabel"
  (open)="onOpen()"
  (change)="onChange($event)"
  (scrollToEnd)="onNextPage()"
  (close)="closeSelect()"
  (search)="onSearch($event)"
  [placeholder]="placeholder"
  notFoundText="Не найдено"
  [(ngModel)]="selectedValue"
  [readonly]="isDisabled"
  [multiple]="multiple"
  (clear)="clear()">
</ng-select>