import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss'],
})
export class ApprovalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  title: string;
  subTitle: string;
  close(isChange) {
    this.activeModal.close(isChange);
  }
}
