import { InjectionToken } from '@angular/core';

export const JWT_TOKEN_KEY = 'token';

export const CURRENT_USER_KEY = 'currentUser';

export const CLIENT_ID = 'htc';

export const VIEW_MANAGER_URI = new InjectionToken('viewManagerURI');

export const USER_MANAGER_URI = new InjectionToken('userManagerURI');

export const DATA_MANAGER_URI = new InjectionToken('dataManagerURI');

export const FILE_MANAGER_URI_TOKEN = new InjectionToken<string>('fileManagerURI');

export const KAZAKHSTAN_COUNTRY_TOKEN = new InjectionToken<number>('kazakhstanCountry');

export const CYPRUS_COUNTRY_TOKEN = new InjectionToken<number>('cyprusCountry');

export const OBJECT_TYPE_ID_TOKEN = new InjectionToken<OBJECT_TYPE_ID>('objectTypeId');

export const SLOGAN = 'маркетплейс видеообзоров и хороших риелторов';

export enum OBJECT_TYPE_ID {
  Buy = 1,
  Sell = 2,
}

export const KZ_COUNTRY_ID = 3211;
export const UZ_COUNTY_ID = 3882;
export const KZ_COUNTRY_CODE = '001';
export const CYPRUS_COUNTRY_CODE = '002';
export const UZ_COUNTRY_CODE = '003';

export const MASK_PHONE = {
  [KZ_COUNTRY_CODE]: ' (000) 000-0000',
  [UZ_COUNTRY_CODE]: ' (00)000-0000',
  [CYPRUS_COUNTRY_CODE]: ' (000) 000-0000',
};

export const PREFIX_PHONE = {
  [KZ_COUNTRY_CODE]: '+7',
  [UZ_COUNTRY_CODE]: '+998',
  [CYPRUS_COUNTRY_CODE]: '+7',
};

export const PHONE_LENGTH = {
  [KZ_COUNTRY_CODE]: 10,
  [UZ_COUNTRY_CODE]: 9,
  [CYPRUS_COUNTRY_CODE]: 10,
};

export const CURRENCIES = {
  Казахстан: '₸',
  Узбекистан: 'у.е.',
  Кипр: '₸',
};

export const CURRENCIES_SHORT = {
  Казахстан: 'тг',
  Узбекистан: 'у.е.',
  Кипр: 'тг',
};

export const COUNTRY_BY_CODE = {
  '001': 'Казахстан',
  '002': 'Кипр',
  '003': 'Узбекистан',
};

export interface COUNTRY_SELECT {
  name: string;
  code: string;
  mask: string;
  flag: string;
}

export const COUNTRIES: COUNTRY_SELECT[] = [
  {
    name: 'Казахстан',
    code: '+7',
    mask: ' (000) 000-0000',
    flag: 'kazakhstan.png',
  },
  {
    name: 'Узбекистан',
    code: '+998',
    mask: '00-000-00-00',
    flag: 'uzbekistan.png',
  },
];
