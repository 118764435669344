export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  authUrl: 'https://idp.jurta.kz/auth/realms/htc/protocol/openid-connect/token',
  apiDataManagerUrl: 'https://dm.jurta.kz',
  apiFileManagerUrl: 'https://fm.jurta.kz',
  apiViewManagerUrl: 'https://vm.jurta.kz',
  apiUserManagerUrl: 'https://um.jurta.kz',
  apiRoleManagerUrl: 'https://rm.jurta.kz',
  apiNotificationManagerUrl: 'https://nm.jurta.kz',
  apiGisManagerUrl: 'https://gm.jurta.kz',
  videoStreamBaseUrl: 'https://storage-api.jurta.kz/',
};
