import { ObjectService } from '@modules/shared/services/object.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from './client.service';
import { EventsService } from './events.service';
import { FileService } from './file.service';
import { GisService } from './gis.service';
import { Notification } from './notification';
import { NotificationService } from './notification.service';
import { ProfileService } from './profile.service';
import { RoleManagerService } from './roleManager.service';
import { UserService } from './user.service';
import { Util } from './util';
import { VideoManagerService } from './videoManager.service';

export const services = [
  ToastrService,
  ProfileService,
  Notification,
  RoleManagerService,
  UserService,
  NotificationService,
  FileService,
  Util,
  GisService,
  EventsService,
  ClientService,
  ObjectService,
  VideoManagerService,
];

export * from './authentication.service';
export * from './client.service';
export * from './dic.service';
export * from './events.service';
export * from './file.service';
export * from './gis.service';
export * from './notification';
export * from './notification.service';
export * from './object.service';
export * from './profile.service';
export * from './roleManager.service';
export * from './user.service';
export * from './util';
export * from './videoManager.service';
