import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-single-floating-point-input',
  templateUrl: './single-floating-point-input.component.html',
  styleUrls: ['./single-floating-point-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SingleFloatingPointInputComponent,
      multi: true,
    },
  ],
})
export class SingleFloatingPointInputComponent {
  @Input()
  formControl: FormControl;

  @Input()
  public readonly name: string = null;

  @Input()
  formControlName: string;

  get control() {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  constructor(private injector: Injector) {}

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  @Input()
  public readonly minlength: number = null;

  @Input()
  public readonly maxlength: number = null;

  @Input()
  public readonly placeholder: any = null;

  @Input()
  public readonly className: string = null;

  @Input()
  public readonly = false;

  @Input()
  public readonly required: boolean = false;

  @Input()
  public readonly inputGroupRight: boolean = false;

  @Input()
  public readonly inputGroupLeft: boolean = false;

  @Output()
  public ngModalChangeEvent = new EventEmitter();

  @Output()
  public changeEvent = new EventEmitter();

  #value = '';

  get value() {
    return this.#value;
  }

  @Input()
  set value(value: any) {
    this.#value = value;
  }

  onChanged: (value: string) => void;

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouched = fn;
  }

  writeValue(value: string): void {
    if (typeof value === 'string') {
      this.#value = value.replace(',', '.');
    }
  }

  public onNgModelChange(event): void {
    this.ngModalChangeEvent.emit(event);
  }

  public onChange(): void {
    this.changeEvent.emit();
  }
}
